/* eslint-disable max-len */
import '../StyleSheets/main.css';
import * as CountryState from 'country-state-city';
import * as localCountry from 'i18n-iso-countries';
import i18next, { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router';
import { Auth } from 'aws-amplify';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { LoadCountriesState } from '../Settings/Language';
import UnsavedChangesWarning from '../Settings/UnsavedChangesWarning';
import { LoadOrganizations, LoadOrganization, SaveOrganization } from './OrganisationLoader';
import { useOrganizationHeaders } from '../Dashboard';
import Organization, { CountryData } from '../Model/Organization';
import { DefaultToastConfig } from '../ToastConfig';
import { GraphQLError, MeasurewebRole, ServiceRole } from '../Model/Enums';
import CheckAdminPermission, { HandleError } from './CheckPermission';
import Avatar from '../Settings/Avatar';
import { GetOrganizationLogo, UploadOrganizationLogo } from '../Settings/AvatarRequests';
import { ThemeContext } from '../Images/ThemeContext';
import CountryCodes from '../CountryCode';

export default function OrganisationSettings(props: { CreateNewToggle: boolean; }) {
  const { theme } = useContext(ThemeContext);
  const [organizationHeader, setOrganizationHeader, setOrganizationImage] = useOrganizationHeaders();
  const [isDirty, setIsDirty] = useState(false);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 30,
      minHeight: 30,
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 5,
      paddingBottom: 5,
    }),
    clearIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 5,
      paddingBottom: 5,
    }),
  };

  const [organizationState, setOrganizationState] = useState(new Organization());
  const [currentCountry, setCurrentCountry] = useState<CountryData>();
  const [currentState, setCurrentState] = useState<CountryData>();
  const [countries, setCountries] = useState<any>();
  const [states, setStates] = useState<any>();
  const [allowDelete, setAllowDelete] = useState<boolean>(false);
  const [CountryCode, setCountryCode] = useState('+49');

  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.ReloadData) {
    location.state.ReloadData = false;
    LoadData();
  }

  useEffect(
    () => {
      Load();
      async function Load() {
        setIsDirty(false);
        LoadCountriesState(organizationState.Country!, setCountries, setStates);
        if (!props.CreateNewToggle)
          await LoadData();
        if (props.CreateNewToggle) {
          setOrganizationState((x) => ({ ...x, ...new Organization() }));
          setCurrentCountry(undefined);
          setCurrentState(undefined);
        }
        const user = await Auth.currentAuthenticatedUser();
        setAllowDelete(organizationHeader.organisationMetaData!
          .some((x: OrganizationMetaData) => (x.OwnerCognitoUsername === user.username && x.IsActive && x.ID.toString() !== user.attributes['custom:CurrentOrganization'])));
      }
    },
    [props.CreateNewToggle, organizationHeader],
  );
  useEffect(() => {
    if (organizationState.Country)
      setStates(CountryState.State.getStatesOfCountry(organizationState.Country!).map((x) => ({ label: t(x.name), value: x.isoCode })));
  }, [organizationState]);

  useEffect(() => {
    if (!props.CreateNewToggle)
      CheckAdminPermission(navigate, organizationHeader);
  }, [organizationHeader]);

  function SplitPhoneNumber(phoneNumber: string) {
    if (phoneNumber.length > 0 && phoneNumber.startsWith('+')) {
      const PhoneNumberSplit = phoneNumber.split('/');
      setCountryCode(PhoneNumberSplit[0]);
      return PhoneNumberSplit[1];
    }
    setCountryCode('+49');
    return '';
  }

  async function LoadData() {
    const { language } = i18next;
    const loadResult = await LoadOrganization();
    if (loadResult === GraphQLError.AccessDenied) {
      HandleError(loadResult as GraphQLError, setOrganizationHeader);
      return;
    }
    const loadedOrganization = loadResult as Organization;

    loadedOrganization.PhoneNumber = SplitPhoneNumber(loadedOrganization.PhoneNumber as string);

    setOrganizationState(loadedOrganization);

    const Country = CountryState.Country.getCountryByCode(loadedOrganization.Country!);
    const State = CountryState.State.getStateByCodeAndCountry(loadedOrganization.State!, loadedOrganization.Country!);
    setCurrentCountry({ label: localCountry.getName(Country?.isoCode as string, language), value: Country?.isoCode as string });
    setCurrentState({ label: t(State?.name as string), value: State?.isoCode });
  }

  const onSave = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if (e !== undefined)
      e.preventDefault();
    if (organizationState.Name === '') {
      toast.error(t('Name cannot be empty'), DefaultToastConfig);
      return;
    }
    setIsSaving(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const saveresponse = await SaveOrganization(new Organization({
        Guid: organizationState.Guid,
        Name: organizationState.Name,
        Trade: organizationState.Trade,
        ShippingAddress: organizationState.ShippingAddress,
        PostCode: organizationState.PostCode,
        City: organizationState.City,
        Country: organizationState.Country,
        State: organizationState.State,
        VATID: organizationState.VATID,
        Email: organizationState.Email,
        // eslint-disable-next-line prefer-template
        PhoneNumber: organizationState.PhoneNumber === '' ? organizationState.PhoneNumber : CountryCode + '/' + organizationState.PhoneNumber,
        Homepage: organizationState.Homepage,
        Xing: organizationState.Xing,
        LinkedIn: organizationState.LinkedIn,
      }), props.CreateNewToggle);
      if (typeof saveresponse === 'number') {
        setIsDirty(false);
        setIsSaving(false);
        HandleError(saveresponse, setOrganizationHeader);
        return;
      }
      const organisation = saveresponse;
      setIsDirty(false);
      if (props.CreateNewToggle)
        toast.success(t('Successfully added the organization'), DefaultToastConfig);
      else toast.success(t('Changes saved succesfully'), DefaultToastConfig);

      if (props.CreateNewToggle)
        await Auth.updateUserAttributes(user, {
          'custom:CurrentOrganization': organisation.Guid!.toString(),
        });
    } finally {
      setIsSaving(false);
    }
    setOrganizationHeader({ ...organizationHeader, loadingFinished: false });
    LoadOrganizations(setOrganizationHeader);
    if (props.CreateNewToggle)
      navigate('/Organization', { state: { ReloadData: true } });
  };

  function setProperty(propertyName: keyof Organization, value: any) {
    organizationState[propertyName] = value;
    setOrganizationState(new Organization(organizationState));
    setIsDirty(true);
  }

  return (
    <div className="PageBody">
      <div id="HeadLine">
        <h2>{props.CreateNewToggle ? t('Create new Organization') : t('Organization settings')}</h2>
        <a>{props.CreateNewToggle
          ? t('Create a new organization and add the address')
          : t('Manage information about your organization, add contact information and social media accounts.')}
        </a>
      </div>
      <UnsavedChangesWarning
        dirtyFlags={[isDirty]}
      />
      <div id="OrganizationSettings">
        {!props.CreateNewToggle
          && (
          <div className="TileBody" id="AvatarEditor">
            <form className="TileForm">
              <div id="BlockHeadLine">
                <h3>{t('Avatar')}</h3>
              </div>
              <Avatar
                UploadAvatar={(file, callback) => UploadOrganizationLogo(file, async () => {
                  callback();
                  setOrganizationImage(await GetOrganizationLogo(organizationState.Guid!, theme, organizationHeader.currentOrganisationName!));
                }, organizationState.Guid!)}
                getAvatar={async () => {
                  const user = await Auth.currentAuthenticatedUser();
                  return GetOrganizationLogo(user.attributes['custom:CurrentOrganization'], theme, organizationHeader.currentOrganisationName!);
                }}
              />
            </form>
          </div>
          )}
        <div className="TileBody">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('About the Organization')}</h3>
            </div>
            <a>{t('Name')}</a>
            <input className="input" type="text" onChange={(e) => setProperty('Name', e.target.value)} value={organizationState.Name} />
            <a>{t('Country')}</a>
            <div id="SelectDiv">
              <Select
                styles={customStyles}
                className="SettingsInput"
                options={countries}
                onChange={(e: any) => { setProperty('Country', e.value); setCurrentCountry(e); }}
                value={currentCountry}
              />
            </div>
            <a>{t('State')}</a>
            <div id="SelectDiv">
              <Select
                styles={customStyles}
                className="SettingsInput"
                options={states}
                onChange={(e: any) => { setProperty('State', e.value); setCurrentState(e); }}
                value={currentState}
              />
            </div>
            <a>{t('postcode')}</a>
            <input className="input" type="text" onChange={(e) => setProperty('PostCode', e.target.value)} value={organizationState.PostCode} />
            <a>{t('city')}</a>
            <input className="input" type="text" onChange={(e) => setProperty('City', e.target.value)} value={organizationState.City} />
            <a>{t('Street')}</a>
            <textarea className="input" rows={5} onChange={(e) => setProperty('ShippingAddress', e.target.value)} value={organizationState.ShippingAddress} />
            <a>{t('VAT-ID')}</a>
            <input className="input" type="text" onChange={(e) => setProperty('VATID', e.target.value)} value={organizationState.VATID} />
            <button type="submit" className="button" onClick={onSave} disabled={!isDirty || isSaving}>
              {isSaving && <i className="loader" />}
              {props.CreateNewToggle ? t('Create Organization') : t('save')}
            </button>
          </form>
        </div>
        {
        !props.CreateNewToggle
        && (
        <>
          <div className="TileBody">
            <form className="TileForm">
              <div id="BlockHeadLine">
                <h3>{t('contact')}</h3>
              </div>
              <a>{t('email')}</a>
              <input className="input" type="e" onChange={(e) => setProperty('Email', e.target.value)} value={organizationState.Email} />
              <a>{t('phone')}</a>
              <div id="PhoneInput">
                <Select
                  isSearchable={false}
                  styles={customStyles}
                  options={CountryCodes()}
                  onChange={(e: any) => { setCountryCode(e.value); setIsDirty(true); }}
                  value={CountryCodes().filter((option) => option.value === CountryCode)}
                />
                <input className="input" type="text" onChange={(e) => { setProperty('PhoneNumber', e.target.value); }} defaultValue={organizationState.PhoneNumber} />
              </div>
              <a>{t('Homepage')}</a>
              <input className="input" type="text" onChange={(e) => setProperty('Homepage', e.target.value)} value={organizationState.Homepage} />
              <a>Xing</a>
              <input className="input" type="text" onChange={(e) => setProperty('Xing', e.target.value)} value={organizationState.Xing} />
              <a>LinkedIn</a>
              <input className="input" type="text" onChange={(e) => setProperty('LinkedIn', e.target.value)} value={organizationState.LinkedIn} />
              <button type="submit" className="button" onClick={onSave} disabled={!isDirty || isSaving}>
                {t('save')}
                {isSaving && <i className="loader" />}
              </button>
            </form>
          </div>
          <div className="TileBody">
            <form className="TileForm">
              <div id="BlockHeadLine">
                <h3>{t('Delete your organization')}</h3>
                <a>{t('When you delete your organization, you lose access to all related DATAflor services and we permanently delete your data.'
                  + ' You can cancel the deletion within 7 days.')}
                </a>
                <a>{t('Learn more')}</a>
              </div>
              <div id="DeleteOrgButton" data-tip={allowDelete ? t('Delete Organization') : t("You can't delete the last organization you own.")}>
                <button className="button" disabled={!allowDelete} onClick={() => navigate('/DeleteOrganization')}>
                  {t('Delete organization')}
                </button>
                <ReactTooltip />
              </div>
            </form>
          </div>
        </>
        )
      }
      </div>
    </div>
  );
}
export class OrganizationMetaData {
  ID: string | number;
  Name: string;
  OwnerCognitoUsername: string;
  MemberRoles?: Array<ServiceRole | MeasurewebRole>;
  IsActive: boolean;

  constructor(ID: string | number, Name: string, OwnerCognitoUsername: string, MemberRoles: Array<ServiceRole | MeasurewebRole>, IsActive: boolean) {
    this.ID = ID;
    this.Name = Name;
    this.OwnerCognitoUsername = OwnerCognitoUsername;
    this.MemberRoles = MemberRoles;
    this.IsActive = IsActive;
  }
}

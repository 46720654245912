import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './StyleSheets/main.css';
import { t } from 'i18next';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-overlays';
import { Auth } from 'aws-amplify';
import { UserMenu } from './Settings/UserMenu';
import {
  Buttons, OrganisationMenuConfiguration, OrganisationMenuIcon, OrganizationMenu,
} from './Settings/OrganisationMenuConfiguration';
import { LoadOrganizations } from './Organisation/OrganisationLoader';
import { HasUserRole } from './OrganizationHeaders';

import { OrganizationHeaders } from './Dashboard';

import logo from './Images/white/DATAflor_Logo.svg';
import measureWeb from './Images/DATAflor-logo.svg';

import serviceConfig from './Login/ServiceConfiguration';
import { MeasurewebRole } from './Model/Enums';
import ThemeSwitch from './ThemeSwitch';
import { ThemeContext } from './Images/ThemeContext';

function Navbar(props: { Data: OrganizationHeaders; setSlideMenuWidth: Dispatch<SetStateAction<string>>; currentOrganizationImage: any;}) {
  const [showUserMenu, setshowUserMenu] = useState(false);
  const [showOrganizationMenu, setShowOrganizationMenu] = useState(false);
  const toggleSlideMenu = () => {
    props.setSlideMenuWidth((x) => (x === 'large-slideMenu' ? 'small-slideMenu' : 'large-slideMenu'));
  };
  const { themeColor, theme } = useContext(ThemeContext);

  return (
    <div id="navbar-nav" data-theme={themeColor}>
      <div id="left-part">
        <button id="SlideMenuToggle" onClick={() => { toggleSlideMenu(); }}>
          <img
            id="slideMenuIconHamburger"
            src={theme.List}
            alt=""
          />
        </button>
        <SwitchServiceMenu header={props.Data} />
      </div>
      <div id="right-part">
        <OrganizationMenu
          metaData={props.Data}
          image={props.currentOrganizationImage}
          toggleState={[showOrganizationMenu, setShowOrganizationMenu]}
          closeUserMenu={() => setshowUserMenu(false)}
        />
        <UserMenu
          toggleState={[showUserMenu, setshowUserMenu]}
          closeOrganizationMenu={() => setShowOrganizationMenu(false)}
        />
      </div>
    </div>
  );
}

function SwitchServiceMenu(props: { header: OrganizationHeaders; }) {
  const [showServiceList, setShowServiceList] = useState(false);
  const [enableMeasureWeb, setEnableMeasureWeb] = useState(false);
  const { theme, themeColor } = useContext(ThemeContext);
  useEffect(() => {
    SetEnabled();
    async function SetEnabled() {
      setEnableMeasureWeb(await HasUserRole(MeasurewebRole.User, props.header));
    }
  }, [props.header]);
  const measureWebUrl = serviceConfig.MeasureWeb.Redirect;
  return (
    <>
      <li id="nav-item">
        <button id="SlideMenuToggle" onClick={() => setShowServiceList(true)}>
          <img
            id="slideMenuIconService"
            src={theme.Grid}
            alt=""
          />
        </button>
      </li>
      <Modal
        className="serviceListOverlay"
        id={themeColor}
        show={showServiceList}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderBackdrop={(props) => <div className="invisibleBackdrop" {...props} />}
        onHide={() => setShowServiceList(false)}
      >
        <div className="centeredText">
          <div data-tip={enableMeasureWeb
            ? t('Switch to MeasureWeb')
            : t('You have no permission to use this service in this organization. Please contact your admin for further information.')}
          >
            <button
              id="CustomDropdownButton"
              onClick={() => window.location.href = measureWebUrl}
              disabled={!enableMeasureWeb}
            >
              <img
                id="ServiceDropdownButton"
                src={measureWeb}
                alt=""
              />
              <span>{t('MeasureWeb')}</span>
            </button>
          </div>
          <ReactTooltip />
        </div>
      </Modal>
    </>
  );
}

function OrganisationHeader(props: { Data: OrganizationHeaders; setData: Dispatch<SetStateAction<OrganizationHeaders>>; }): JSX.Element {
  const navigate = useNavigate();
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    async function getOptions() {
      setOptions(await OrganisationMenuConfiguration(props.Data));
    }
    getOptions();
  }, [props.Data]);

  return (
    <Select
      className="HeaderSelector"
      isSearchable={false}
      value={OrganisationMenuIcon()}
      options={options}
      isOptionDisabled={(option :any) => option.isdisabled}
      onChange={(x) => onClickOrganisation(x, navigate, [props.Data, props.setData])}
    />
  );
}

async function onClickOrganisation(x :any, navigate: any, [, setData]: [OrganizationHeaders, Dispatch<SetStateAction<OrganizationHeaders>>]) {
  const user = await Auth.currentAuthenticatedUser();
  switch (x?.value) {
    case Buttons.Organization:
      navigate(x?.value as string, { state: { ReloadData: true } });
      break;
    case Buttons.OrganizationList:
    case Buttons.CreateOrganization:
      navigate(x?.value as string);
      break;
    default:
      await Auth.updateUserAttributes(user, {
        'custom:CurrentOrganization': x?.value.toString(),
      });
      LoadOrganizations(setData);
      navigate('Organization', { state: { ReloadData: true } });
      break;
  }
}

export function MenuItem(props: { onClick: () => void; leftIcon?: any; children: any; SlideMenuWidth: string; }) {
  return (
    <div className="MenuItem" id={props.SlideMenuWidth} onClick={() => props.onClick()}>
      <span>{props.leftIcon}</span>
      <p id="MenuItemParagraph">{props.children}</p>
    </div>

  );
}

export function SlideMenu(props: { SlideMenuWidth: string }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useContext(ThemeContext);

  switch (location.pathname) {
    case '/Organization':
    case '/CreateOrganization':
    case '/DeleteOrganization':
    case '/Subscriptions':
    case '/OrganizationList':
    case '/Users': {
      return (
        <div>
          <div id="SlideMenuColorDiv">
            <div id="SubColorDiv">
              <img
                id="PlaceholderImg"
                src={logo}
                alt=""
              />
            </div>
          </div>
          <MenuItem
            leftIcon={(
              <img
                id="slideMenuIcon"
                src={theme.House}
                alt=""
              />
)}
            onClick={() => navigate('/')}
            SlideMenuWidth={props.SlideMenuWidth}
          >{t('Home')}
          </MenuItem>
          <MenuItem
            leftIcon={(
              <img
                id="slideMenuIcon"
                src={theme.Building}
                alt=""
              />
)}
            onClick={() => navigate('Organization')}
            SlideMenuWidth={props.SlideMenuWidth}
          >{t('Organization settings')}
          </MenuItem>
          <MenuItem
            leftIcon={(
              <img
                id="slideMenuIcon"
                src={theme.PersonPlus}
                alt=""
              />
)}
            onClick={() => navigate('Users')}
            SlideMenuWidth={props.SlideMenuWidth}
          >{t('User management')}
          </MenuItem>
        </div>
      );
    }
    case '/':
    case '/Dashboard': {
      return (
        <div id="menu-slide">
          <div id="SlideMenuColorDiv">
            <div id="SubColorDiv">
              <img
                id="PlaceholderImg"
                src={logo}
                alt=""
              />
            </div>
          </div>
        </div>
      );
    }
    default: {
      return (
        <div id="menu-slide">
          <div id="SlideMenuColorDiv">
            <div id="SubColorDiv">
              <img
                id="PlaceholderImg"
                src={logo}
                alt=""
              />
            </div>
          </div>
          <MenuItem
            leftIcon={(
              <img
                id="slideMenuIcon"
                src={theme.House}
                alt=""
              />
)}
            onClick={() => navigate('/')}
            SlideMenuWidth={props.SlideMenuWidth}
          >{t('Home')}
          </MenuItem>
          <MenuItem
            leftIcon={(
              <img
                id="slideMenuIcon"
                src={theme.Person}
                alt=""
              />
)}
            onClick={() => navigate('Profile')}
            SlideMenuWidth={props.SlideMenuWidth}
          >{t('profile')}
          </MenuItem>
          <MenuItem
            leftIcon={(
              <img
                id="slideMenuIcon"
                src={theme.ShieldLock}
                alt=""
              />
)}
            onClick={() => navigate('Account')}
            SlideMenuWidth={props.SlideMenuWidth}
          >{t('account')}
          </MenuItem>
        </div>
      ); }
  }
}

export default Navbar;

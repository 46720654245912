import { Dispatch, SetStateAction } from 'react';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'i18next';
import { OrganizationMetaData } from './OrganisationSettings';
import {
  GetRoleByID, GraphQLError, MeasurewebRole, Service, ServiceRole,
} from '../Model/Enums';
import OrganizationMember from '../Model/OrganizationMember';
import { Role } from '../Model/MemberRole';
import Invitation from '../Model/Invitation';
import Organization from '../Model/Organization';
import { OrganizationHeaders } from '../OrganizationHeaders';
import { Deserialize } from './Deserialize';

export async function LoadOrganizations(setData: Dispatch<SetStateAction<OrganizationHeaders>>) {
  const user = await Auth.currentAuthenticatedUser();
  let organizationMetaData: OrganizationMetaData[] = [];
  // After registration memberships might still be empty. So we retry.
  for (let i = 0; i < 7 && organizationMetaData?.length === 0; i++) {
    // eslint-disable-next-line no-await-in-loop
    const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
      query getOrganisationMemberByUsername($CognitoUsername: ID!, $accessKey: ID!) { 
        getOrganisationMemberByUsername(CognitoUsername: $CognitoUsername, accessKey: $accessKey) 
        { 
          Guid,
          Organisation{
            Guid,
            OwnerCognitoUsername,
            Name,
            IsActive
          },
          MemberRoles{
            Role{
              ID
            }
          }
        } 
      }
      `,
        variables: {
          CognitoUsername: user.username,
          accessKey: user.signInUserSession.accessToken.jwtToken,
        },
      }),
    });
    // eslint-disable-next-line no-await-in-loop
    const json = await response.json();
    const organizationNames : [any] = json.data.getOrganisationMemberByUsername;
    organizationMetaData = organizationNames.filter((x) => x.Organisation !== null)
      .map((x: any) => new OrganizationMetaData(
        x.Organisation.Guid,
        x.Organisation.Name,
        x.Organisation.OwnerCognitoUsername,
        x.MemberRoles.map((y : any) => GetRoleByID(y.Role.ID)),
        x.Organisation.IsActive,
      ));
    if (organizationMetaData.length === 0)
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => { setTimeout(resolve, 1000 * i); });
  }

  organizationMetaData.forEach((org) => {
    if (org.Name === 'ORGANIZATION_DEFAULT_NAME') {
      // eslint-disable-next-line no-param-reassign
      org.Name = t('ORGANIZATION_DEFAULT_NAME');
    }
  });

  const activeOrganization = await GetActiveOrganization(organizationMetaData);
  const activeOrganizationName = activeOrganization?.Name ?? '';
  const newData = new OrganizationHeaders(activeOrganizationName, organizationMetaData, true);

  setData(newData);
  return newData;
}

async function GetActiveOrganization(organisationMetaData: OrganizationMetaData[]) : Promise<OrganizationMetaData | undefined> {
  let user = await Auth.currentAuthenticatedUser({ bypassCache: true });
  let activeOrganizationID = user.attributes['custom:CurrentOrganization'];

  // After registration the currentOrganization might not be set yet, in which case we do some retries.
  for (let i = 0; i < 5 && activeOrganizationID === undefined; i++) {
    user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    activeOrganizationID = user.attributes['custom:CurrentOrganization'];
    await new Promise((resolve) => { setTimeout(resolve, 1000 * i); });
  }

  return organisationMetaData?.find((x) => x.ID.toString() === activeOrganizationID);
}

export async function LoadOrganization() : Promise<Organization | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query:
    `
      query organisation($organization: ID!, $accessKey: ID!) { 
        organisation(accessKey: $accessKey, ID: $organization) 
        { 
          Guid,
          Trade,
          Name,
          ShippingAddress,
          PostCode,
          City,
          Country,
          State,
          VATID,
          Email,
          PhoneNumber,
          Homepage,
          Xing,
          LinkedIn
        } 
      }
    `,
      variables: {
        organization: user.attributes['custom:CurrentOrganization'],
        accessKey: user.signInUserSession.accessToken.jwtToken,
      },
    }),
  });
  const json = await response.json();
  const organization = Deserialize(Organization, json, 'organisation');
  if (organization instanceof Organization && organization.Name === 'ORGANIZATION_DEFAULT_NAME') {
    // eslint-disable-next-line no-param-reassign
    organization.Name = t('ORGANIZATION_DEFAULT_NAME');
  }
  return organization;
}

export async function SaveOrganization(organization: Organization, createNew: boolean) : Promise<Organization | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: createNew
        // eslint-disable-next-line max-len
        ? 'mutation ($organisation:OrganisationInput!, $ownerID: ID!, $accessKey: ID!){ createOrganisation(organisation: $organisation, ownerID: $ownerID, accessKey: $accessKey) { Guid Name} }'
        : 'mutation ($organisation:OrganisationInput!, $accessKey: ID!){ updateOrganisation(organisation: $organisation, accessKey: $accessKey) { Guid Name} }',
      variables: createNew
        ? {
          organisation: organization,
          ownerID: user.username,
          accessKey: user.signInUserSession.accessToken.jwtToken,
        }
        : {
          organisation: organization,
          accessKey: user.signInUserSession.accessToken.jwtToken,
        },
    }),
  });
  const json = await response.json();
  return Deserialize(Organization, json, 'createOrganisation');
}

export async function DeactivateOrganization() : Promise<void | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      mutation deactivateOrganization($accessKey: ID!, $organization: ID!) { 
        deactivateOrganization(accessKey: $accessKey, ID: $organization){
          Guid
        }
      }
    `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
        organization: user.attributes['custom:CurrentOrganization'],
      },
    }),
  });
  const json = await response.json();
  if (json?.errors) {
    if (json.errors[0].message in GraphQLError)
      return GraphQLError[json.errors[0].message as keyof typeof GraphQLError];
    return GraphQLError.Unknown;
  }
}

export async function ReactivateOrganization(organizationID: string | number) : Promise<void | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      mutation reactivateOrganization($accessKey: ID!, $organization: ID!) { 
        reactivateOrganization(accessKey: $accessKey, ID: $organization){
          Guid
        }
      }
    `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
        organization: organizationID.toString(),
      },
    }),
  });
  const json = await response.json();
  if (json?.errors) {
    if (json.errors[0].message in GraphQLError)
      return GraphQLError[json.errors[0].message as keyof typeof GraphQLError];
    return GraphQLError.Unknown;
  }
}

export async function LoadMembers(orgID: string | undefined = undefined) : Promise<OrganizationMember[]> {
  const user = await Auth.currentAuthenticatedUser();
  const OrgID = orgID || user.attributes['custom:CurrentOrganization'];
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query getOrganisationMemberByOrganization($ID: ID!, $accessKey: ID!) { 
        getOrganisationMemberByOrganization(Organization: $ID, accessKey: $accessKey) 
        { 
          Guid,
          User{
            CognitoUsername,
            Surname,
            LastName,
            CognitoEmail
          },
          MemberRoles{
            Role{
              ID,
              Name
            }
          }
        } 
      }
    `,
      variables: {
        ID: OrgID,
        accessKey: user.signInUserSession.accessToken.jwtToken,
      },
    }),
  });
  const json = await response.json();
  return json.data.getOrganisationMemberByOrganization.map((x : any) => new OrganizationMember(x));
}
export async function SetUserRole(cognitoUsername: string, role: ServiceRole | MeasurewebRole, service: Service): Promise<Role | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const OrgID = user.attributes['custom:CurrentOrganization'];
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      mutation setRoleForUser($accessKey: ID!, $cognitoUsername: ID!, $organization: ID!, $role: ID!, $service: ID!) { 
        setRoleForUser(accessKey: $accessKey, cognitoUsername: $cognitoUsername, organization: $organization, roleID: $role, service: $service) 
        { 
          ID
        } 
      }
    `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
        cognitoUsername,
        organization: OrgID,
        role: role.toString(),
        service,
      },
    }),
  });
  const json = await response.json();
  return Deserialize(Role, json, 'setRoleForUser');
}

export async function LoadInvitations() : Promise<Invitation[]> {
  const user = await Auth.currentAuthenticatedUser();
  const OrgID = user.attributes['custom:CurrentOrganization'];
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      query getInvitations($ID: ID!, $accessKey: ID!) { 
        getInvitations(organization: $ID, accessKey: $accessKey) 
        { 
          ID,
          OrganizationID,
          Email,
          MyDataflorID,
          MeasureWebID,
          Invalidated
        } 
      }
    `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
        ID: OrgID,
      },
    }),
  });
  const json = await response.json();
  return json.data.getInvitations.map((x : any) => new Invitation(x));
}
export async function InvalidateInvitation(ID: string) : Promise<void | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      mutation invalidateInvitation($ID: ID!, $accessKey: ID!) { 
        invalidateInvitation(ID: $ID, accessKey: $accessKey) 
      }
    `,
      variables: {
        ID,
        accessKey: user.signInUserSession.accessToken.jwtToken,
      },
    }),
  });
  const json = await response.json();
  if (json?.errors) {
    if (json.errors[0].message as keyof typeof GraphQLError)
      return GraphQLError[json.errors[0].message as keyof typeof GraphQLError];
    return GraphQLError.Unknown;
  }
}

function getRandomID() {
  return uuidv4();
}

export async function SendInvite(
  Mail: string,
  MyDataflorRole: ServiceRole,
  MeasureWebRole: MeasurewebRole,
  mailLanguage: string,
): Promise<boolean | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const OrgID = user.attributes['custom:CurrentOrganization'];
  const randomID = getRandomID().toString();

  const inviteJson = JSON.stringify(
    {
      ID: randomID,
      OrgID,
      MyDataflorRole: MyDataflorRole.toString(),
      MeasureWebRole: MeasureWebRole.toString(),
      Mail,
      Locale: mailLanguage,
      MailType: 'organizationInvite',
      Link: `https://${window.location.hostname}/OrganizationInvite/${randomID}`,
      accessKey: user.signInUserSession.accessToken.jwtToken,
    },
  );
  const mailPost = {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: inviteJson,
  };
  try {
    const data = await fetch(`${process.env.REACT_APP_BackendApi}/OrganizationInvite`, mailPost);
    const json = await data.json();
    if (json.Error === 'AccessDenied')
      return GraphQLError.AccessDenied;
    console.log(data);
    return data.ok;
  } catch (err) {
    console.log(`Error while sending invitations:${err}`);
    return false;
  }
}

export async function RemoveMember(memberID: string): Promise<void | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
      mutation removeMember($accessKey: ID!, $memberID: ID!, ) { 
        removeMember(accessKey: $accessKey, memberID: $memberID){
          ID
        }
      }
    `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
        memberID,
      },
    }),
  });
  const json = await response.json();
  if (json?.errors) {
    if (json.errors[0].message as keyof typeof GraphQLError)
      return GraphQLError[json.errors[0].message as keyof typeof GraphQLError];
    return GraphQLError.Unknown;
  }
}

export async function UpdateInvite(invite: Invitation) : Promise<void | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    body: JSON.stringify({
      query: `
      mutation updateInvitation($accessKey: ID!, $invite: OrganizationInviteInput!) { 
        updateInvitation(accessKey: $accessKey, invite: $invite) 
      }
    `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
        invite,
      },
    }),
  });
  const json = await response.json();
  if (json?.errors) {
    if (json.errors[0].message as keyof typeof GraphQLError)
      return GraphQLError[json.errors[0].message as keyof typeof GraphQLError];
    return GraphQLError.Unknown;
  }
}

import { GraphQLError } from '../Model/Enums';

export function Deserialize<T>(Type: { new(partial: Partial<T>): T; }, json: any, queryIdentifier: string): T | GraphQLError {
  if (json?.errors) {
    if (json.errors[0].message in GraphQLError)
      return GraphQLError[json.errors[0].message as keyof typeof GraphQLError];
    return GraphQLError.Unknown;
  }
  return new Type(json.data[queryIdentifier] as Partial<T>);
}

export default Deserialize;

/* eslint-disable max-len */
import React, { useState, SetStateAction, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';
import UnsavedChangesWarning from './UnsavedChangesWarning';
import PasswordChecklist from '../PasswordChecklist';
import i18n from '../../i18n';
import '../StyleSheets/main.css';
import { Language, LanguageSelector, setLanguageAndCookie } from './Language';
import UrlPaths from '../Urls';
import EmailCodeValidationOverlay from './EmailCodeValidationOverlay';
import CustomizedModal from '../Modal';

export default function Account() {
  const navigate = useNavigate();

  // Save dirty state, for enabling save buttons.
  const [IsMailAdressDirty, setIsMailAdressDirty] = useState(false);
  const [IsPasswordDirty, setIsPasswordDirty] = useState(false);
  const [open, setOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const [LoginMail, setLoginMail] = useState('');
  const [OldPassword, setOldPassword] = useState('');
  const [Password, setPassword] = useState('');
  const [RepeatPassword, setRepeatPassword] = useState('');

  const [, setCookie] = useCookies();

  const [isTwoFactorAllowed, setisTwoFactorAllowed] = useState(true);
  const [multiFactorEnabled, setMultiFactorEnabled] = useState(false);
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((x) => {
      if (x.username.includes('google'))
        setisTwoFactorAllowed(false);
      setLoginMail(x.attributes.email);
      Auth.userAttributes(x).then((attributes) => {
        const attribute = attributes.find((a) => a.Name === 'custom:MultiFactorEmail');
        const mail = attribute?.getValue();
        const mailTwoFactorEnabled = typeof mail !== 'undefined';
        Auth.getPreferredMFA(x).then((mfaPreferences) => {
          setMultiFactorEnabled(mailTwoFactorEnabled || mfaPreferences === 'SOFTWARE_TOKEN_MFA');
        });
      });
    });
  }, []);
  const [showOverlay, setShow] = useState(false);

  function handlePasswordChanged(x: { target: { value: SetStateAction<string> } }) {
    setPassword(x.target.value);
  }

  function handleRepeatPasswordChanged(x: { target: { value: SetStateAction<string> } }) {
    setRepeatPassword(x.target.value);
  }

  function handleLoginMailChanged(x: { target: { value: SetStateAction<string> } }) {
    setLoginMail(x.target.value);
    setIsMailAdressDirty(true);
  }

  async function onSaveMail(e?: React.MouseEvent<HTMLButtonElement>) {
    e?.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.accessToken.jwtToken;
    console.log(token);
    const json = JSON.stringify(
      {
        mail: LoginMail,
        multifactor: false,
        locale: i18n.language,
        mailtype: 'verificationCode',
        jwt: token,
      },
    );
    const mailPost = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: json,
    };
    fetch(`${process.env.REACT_APP_BackendApi}/VerificationEmail`, mailPost)
      .then((response) => {
        console.log(`raw${JSON.stringify(response)}`);
        Auth.currentUserInfo().then((x) => {
          setLoginMail(x.attributes.email);
        });
      })
      .catch((err) => console.log(`err:${err}`));
    setShow(true);
  }

  // eslint-disable-next-line no-undef
  async function onSavePassword(e?: React.MouseEvent<HTMLButtonElement>) {
    if (e !== undefined)
      e.preventDefault();
    await Auth.changePassword(await Auth.currentAuthenticatedUser(), OldPassword, Password)
      .then(() => toast.success(t('Changes saved succesfully'), {
        position: 'bottom-right',
        autoClose: 3000,
      }))
      .catch((err) => toast.error(err.message, {
        position: 'bottom-right',
        autoClose: 3000,
      }));
    setPassword('');
    setOldPassword('');
    setRepeatPassword('');
    setIsPasswordDirty(false);
  }

  async function onSaveLanguage(language: Language) {
    setLanguageAndCookie(language, setCookie);
    await Auth.updateUserAttributes(await Auth.currentAuthenticatedUser(), { locale: i18n.language });
  }

  function closeOverlay(success = false) {
    setShow(false);
    setIsMailAdressDirty(false);

    if (success) {
      toast.success(t('Changes saved succesfully'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
    } else {
      toast.error(t('E-Mail is not changed'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
    }
    Auth.currentUserInfo().then((x) => {
      setLoginMail(x.attributes.email);
      setIsMailAdressDirty(false);
    });
  }

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('account settings')}</h2>
        <a>{t('Manage your account settings and change your login credentials, your language or active multi-factor authentication for the login')}</a>
      </div>
      <div id="AccountSettings">
        <div className="TileBody" id="ChangeMail">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('E-Mail address for login')}</h3>
            </div>
            <a>{t('Change the e-mail adress for your login.')}</a>
            <input
              className="input"
              autoFocus
              type="text"
              value={LoginMail}
              onChange={handleLoginMailChanged}
            />
            <button
              type="submit"
              className="button"
              onClick={onSaveMail}
              disabled={!IsMailAdressDirty}
            >
              {t('save')}
            </button>
          </form>
        </div>
        <div className="TileBody" id="ChangePassword">
          <form className="TileForm" autoComplete="off">
            <div id="BlockHeadLine">
              <h3>{t('Change Password')}</h3>
            </div>
            <a>{t('passwordOld')}</a>
            <input
              className="input"
              type="password"
              value={OldPassword}
              autoComplete="off"
              onChange={(x) => setOldPassword(x.target.value)}
            />
            <a>{t('passwordNew')}</a>
            <input
              className="input"
              type="password"
              autoComplete="new-password"
              value={Password}
              onChange={handlePasswordChanged}
              onFocus={() => setOpen(true)}
            />
            <a>{t('passwordEnter')}</a>
            <input
              className="input"
              type="password"
              autoComplete="new-password"
              value={RepeatPassword}
              onChange={handleRepeatPasswordChanged}
              onBlur={() => setOpen(false)}
            />
            {open && <PasswordChecklist password={Password} passwordAgain={RepeatPassword} onIsValidChanged={setIsValid} />}
            <button
              type="submit"
              className="button"
              onClick={onSavePassword}
              disabled={!isValid}
            >
              {t('save')}
            </button>
          </form>
        </div>
        <div className="TileBody" id="Security">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('Security')}</h3>
              <a>{t('Protect access to your account with two-factor authentication')}</a>
            </div>
            <span
              id="greenSpan"
              hidden={!multiFactorEnabled}
            > {t('Activated')}
            </span>
            <button
              className="button"
              type="submit"
              disabled={!isTwoFactorAllowed}
              title={t('For accounts using google logins, please setup multi factor using google.')}
              onClick={() => { if (multiFactorEnabled) { navigate('/MultiFactorDisable'); } else { navigate('/MultiFactor'); } }}
            > {!multiFactorEnabled ? t('Enable') : t('Disable') }
            </button>

            <CustomizedModal
              show={showOverlay}
              onHide={() => setShow(false)}
            >
              <EmailCodeValidationOverlay closeOverlay={closeOverlay} multifactor={false} />
            </CustomizedModal>
          </form>
        </div>
        <div className="TileBody" id="Language">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('Language')}</h3>
              <a>{t('Changes to your language will be reflected across all services and pages')}</a>
            </div>
            <div id="language-selector">
              <LanguageSelector LanguageChangedCallback={onSaveLanguage} />
            </div>
          </form>
        </div>
        <div className="TileBody" id="DeleteAccount">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('Delete your account')}</h3>
              <a>{t('When you delete your account, you lose access to DATAflor account services, and we permanently delete your personal data. You can cancel the deletion within 7 days')}</a>
            </div>
            <button
              type="submit"
              className="button"
              onClick={() => { navigate(UrlPaths.DeleteAccount); }}
            >
              {t('Delete account')}
            </button>
          </form>
        </div>
      </div>
      <br />
      <UnsavedChangesWarning
        dirtyFlags={[IsMailAdressDirty, IsPasswordDirty]}
      />
    </div>
  );
}

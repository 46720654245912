import { t } from 'i18next';
import '../StyleSheets/main.css';

export default function Subscriptions() {
  return (
    <div id="menu-slide">
      {t('Work in Progress.')}
    </div>
  );
}

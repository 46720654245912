import { t } from 'i18next';
import './StyleSheets/main.css';

function Footer() {
  return (
    <div id="footer">
      <div id="footer-column">
        <a href="https://www.dataflor.de/kontakt/impressum/">{t('Impressum')}</a>
      </div>
      <div id="footer-column">
        <a href="https://www.dataflor.de/kontakt/datenschutzerklaerung/">{t('Privacy')}</a>
      </div>
    </div>
  );
}

export default Footer;

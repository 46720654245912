import { t } from 'i18next';
import { Auth } from 'aws-amplify';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useNavigate } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { Dispatch, SetStateAction, useContext } from 'react';
import { GroupBase } from 'react-select';

import '../StyleSheets/main.css';
import { OrganizationHeaders } from '../OrganizationHeaders';
import { OrganizationMetaData } from '../Organisation/OrganisationSettings';
import { ServiceRole } from '../Model/Enums';
import { ThemeContext } from '../Images/ThemeContext';

export function OrganizationMenu(props: {image: string, metaData: OrganizationHeaders, toggleState: [boolean, Dispatch<SetStateAction<boolean>>], closeUserMenu: () => void}) {
  const [toggleDropdown, setToggleDropdown] = props.toggleState;

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    // We stop event propagation, so the opening click doesn't also immediately close the dropdown.
    event.stopPropagation();
    props.closeUserMenu();
    setToggleDropdown(!toggleDropdown);
  };

  const closeToggle = () => {
    setToggleDropdown(false);
  };

  return (
    <div id="MenuDiv">
      <button id="OrganizationMenuButton" onClick={handleToggle}>
        <img
          id="OrganizationMenuImg"
          src={props.image}
          alt=""
        />
      </button>
      {toggleDropdown
      && (
      <OrganizationDropDown
        organizationName={props.metaData.currentOrganisationName!}
        image={props.image}
        closeToggle={closeToggle}
        setToggleDropdown={setToggleDropdown}
      />
      )}
    </div>
  );
}

function OrganizationDropDown(props: { closeToggle: any; setToggleDropdown: any; organizationName: string; image: any;}) {
  const ref = useDetectClickOutside({ onTriggered: props.closeToggle });
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);

  return (
    <div id="CustomDropdown" ref={ref}>
      <button id="organizationdropdown">
        <img
          src={props.image}
          alt=""
        />
        {props.organizationName}
      </button>
      <div className="Seperator" />
      <button
        id="CustomDropdownButton"
        onClick={() => { props.setToggleDropdown(false); navigate('Organization'); }}
      >
        <img
          src={theme.Gear}
          alt=""
        />
        <span>{t('settings')}</span>
      </button>
      <button
        id="CustomDropdownButton"
        onClick={() => { props.setToggleDropdown(false); navigate('OrganizationList'); }}
      >
        <img
          src={theme.Switching}
          alt=""
        />
        <span>{t('Switch Organisation')}</span>
      </button>
      <button
        id="CustomDropdownButton"
        onClick={() => { props.setToggleDropdown(false); navigate('CreateOrganization'); }}
      >
        <img
          src={theme.PlusCircle}
          alt=""
        />
        <span>{t('addOrganisation')}</span>
      </button>
    </div>
  );
}

export async function OrganisationMenuConfiguration(
  organizationHeader : OrganizationHeaders,
// eslint-disable-next-line max-len
) : Promise<readonly ({ value: string; label: JSX.Element; isdisabled?: boolean; } | GroupBase<{ value: string; label: JSX.Element; isdisabled?: boolean; }>)[]> {
  const { theme } = useContext(ThemeContext);
  if (!organizationHeader.loadingFinished)
    return getLoadingMenu();
  const user = await Auth.currentAuthenticatedUser();
  if (organizationHeader.organisationMetaData?.length === 0)
    return [];
  const NewOrganizationDisabled = await currentOrgs(organizationHeader.organisationMetaData!.filter((x) => x.OwnerCognitoUsername === user.username).length);
  return [
    {
      label: organizationHeader?.currentOrganisationName,
      options: [{
        value: Buttons.Organization,
        label: OrganisationLabel(t('settings'), theme.Gear),
        isdisabled: !IsMemberAdmin(organizationHeader.organisationMetaData!, user.attributes['custom:CurrentOrganization']),
      }],
    },
    {
      label: t('Switch Organisation') as string,
      options: GroupedConfiguration(organizationHeader.organisationMetaData
        ?.filter((x: OrganizationMetaData) => x.ID.toString() !== user.attributes['custom:CurrentOrganization']) ?? []),
    },
    {
      options: [{
        value: Buttons.CreateOrganization,
        label: OrganisationLabel(t('addOrganisation'), theme.PlusCircle, NewOrganizationDisabled),
        isdisabled: NewOrganizationDisabled,
      }],
    },
  ];
}

function getLoadingMenu() {
  return [{
    label:
  <label>
    <i className="loader" />
    {t('Loading')}
  </label>,
    value: '',
  }];
}

export function IsMemberAdmin(organisations: OrganizationMetaData[], id: any) {
  const organization = organisations.find((x) => x.ID.toString() === id);
  return organization!.MemberRoles?.some((y) => y === ServiceRole.Admin);
}

export function GroupedConfiguration(organisations: OrganizationMetaData[]) : readonly { value: string; label: JSX.Element; }[] {
  const result: { value: string; label: JSX.Element; }[] = [];
  organisations.filter((x) => x.IsActive).forEach((x) => { result.push({ value: x.ID.toString(), label: <div>{x.Name}</div> }); });
  result.push({ value: Buttons.OrganizationList, label: <a className="underlinedArticle">{t('All Organizations')}</a> });
  return result as readonly { value: string; label: JSX.Element; }[];
}

export function OrganisationMenuIcon() {
  return [
    {
      value: 'Organisation',
      label: OrganisationButton(),
    },
  ];
}

async function currentOrgs(count: number) {
  const userMaxOrgs = await getMaxOrganizations();

  if (count < userMaxOrgs)
    return false;
  return true;
}

async function getMaxOrganizations() : Promise<number> {
  const user = await Auth.currentAuthenticatedUser();
  const userMaxOrgs : number = user.attributes['custom:MaxOrganizations'];
  const defaultOrganizationMaximum = process.env.REACT_APP_MaxOrganizations!;
  const defaultOrganizationMaximumInt = parseInt(defaultOrganizationMaximum, 10);

  if (userMaxOrgs === undefined)
    return defaultOrganizationMaximumInt;

  return Math.max(userMaxOrgs, defaultOrganizationMaximumInt);
}

function OrganisationButton() {
  const { theme } = useContext(ThemeContext);
  return (
    <div>
      <img
        className="headerMenuImg"
        src={theme.Building}
        alt=""
      />
    </div>
  );
}

function OrganisationLabel(label: string, imageSource: string, tooltip?: boolean) : JSX.Element {
  if (tooltip) {
    return (
      <div data-tip={t('You have reached the maximum number of organizations. To create an additional organization please contact our support for further assistants')}>
        <ReactTooltip />
        <img
          className="headerMenuImg"
          src={imageSource}
          alt=""
        />
        {label}
      </div>
    );
  }
  return (
    <div>
      <img
        className="headerMenuImg"
        src={imageSource}
        alt=""
      />
      {label}
    </div>
  );
}

export enum Buttons{
  Organization = 'Organization',
  CreateOrganization = 'CreateOrganization',
  OrganizationList = 'OrganizationList',
}

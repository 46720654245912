export default class User {
  CognitoUsername?: string = '';
  CognitoEmail?: string = '';
  LastName?: string = '';
  Surname?: string = '';
  Address?: string = '';
  City?: string = '';
  PostCode?: string = '';
  Country?: string;
  State?: string;
  ContactEmail?: string = '';
  PhoneNumber?: string = '';
  Xing?: string = '';
  LinkedIn?: string = '';

  public constructor(init?:Partial<User>) {
    Object.assign(this, init);
  }
}

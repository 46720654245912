import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { t } from 'i18next';
import PasswordChecklist from '../PasswordChecklist';
import '../StyleSheets/main.css';
import '../StyleSheets/login.css';
import { Session, useSession } from './Loginpage';
import { SignIn } from './Login';

export default function RetrievePasswordConfirmation() {
  const [session, setSession] = useSession();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [, setCookie] = useCookies();
  const [cookie] = useCookies(['language']);
  const [isValid, setIsValid] = useState<boolean>(false);

  const [code, setCode] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [newPasswordConfirmation, setnewPasswordConfirmation] = useState('');

  function resendMail() {
    Auth.forgotPassword(session.mail!)
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
    toast.success(t('Resend E-Mail.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  }

  const confirmPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (newPassword !== newPasswordConfirmation) {
      toast.error(t('Passwords must match. Please try again'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      return;
    }
    try {
      const submitNewPasswordResponse = await Auth.forgotPasswordSubmit(session.mail!, code.trim(), newPassword);
      console.log(submitNewPasswordResponse);
      const newSession = new Session(session);
      newSession.password = newPassword;
      await SignIn(newSession, setSession, navigate, cookie, setCookie);
      toast.success(t('Your password has changed.'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
    } catch (error : any) {
      if (error?.code === 'CodeMismatchException')
        toast.error(t('Your code is incorrect. Please check your code and try again.'), {
          position: 'bottom-right',
          autoClose: 3000,
        });
      console.log(error);
    }
  };

  return (
    <div id="loginbox">
      <div id="login-body">
        <form>
          <div id="single-text">
            {t('We have sent you an e-mail to your e-mail address in order to reset the password of your DATAflor cloud account.')}
            <br />
            {t('Please enter the verification code and change your password.')}
          </div>
          <input id="loginbox-input" type="text" onChange={(x) => setCode(x.target.value)} placeholder={t('Verification Code')} />
          <div onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            <input id="loginbox-input" type="password" autoComplete="new-password" onChange={(x) => setnewPassword(x.target.value)} placeholder={t('New password')} />
            <input
              id="loginbox-input"
              type="password"
              autoComplete="new-password"
              onChange={(x) => setnewPasswordConfirmation(x.target.value)}
              placeholder={t('Confirm password')}
            />
          </div>
          {open && <PasswordChecklist password={newPassword} onIsValidChanged={setIsValid} passwordAgain={newPasswordConfirmation} />}
          <button id="loginbox-button" type="submit" className="basicButton" onClick={confirmPassword} disabled={!isValid}>
            {t('Confirm')}
          </button>
          <button id="loginbox-button" className="basicButton" onClick={() => navigate('/RetrievePassword')}>
            {t('back')}
          </button>
        </form>
      </div>
    </div>
  );
}

import { Auth } from 'aws-amplify';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import '../StyleSheets/main.css';
import { ThemeContext } from '../Images/ThemeContext';

export default function MultiFactor() {
  const navigate = useNavigate();
  const [multiFactorEmailEnabled, setMultiFactorEmailEnabled] = useState(false);
  const [multiFactorAppEnabled, setMultiFactorAppEnabled] = useState(false);
  const { theme } = useContext(ThemeContext);

  Auth.currentAuthenticatedUser().then((x) => {
    Auth.getPreferredMFA(x).then((mfaPreferences) => {
      setMultiFactorAppEnabled(mfaPreferences === 'SOFTWARE_TOKEN_MFA');
    });
    Auth.userAttributes(x).then((attributes) => {
      const attribute = attributes.find((a) => a.Name === 'custom:MultiFactorEmail');
      const mail = attribute?.getValue();
      setMultiFactorEmailEnabled(typeof mail !== 'undefined');
    });
  });

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('Two factor authentication')}</h2>
        <a>{t('Two factor authentication refers to a proof of identity using two independent factors.')}</a>
        <a>{t('During login you will be prompted to enter a verification code in addition to your email and password. This code will be provided the second factor.')}</a>
        <a>{t('The DATAflor cloud offers two options for two factor authentication')}</a>
      </div>
      <div id="Multifactor">
        <div id="MfaTile">
          <form>
            <div id="BlockHeadLine">
              <h3>{t('E-Mail')}</h3>
            </div>
            <img
              src={theme.EmailAuth}
              alt=""
            />
            <a>{t('1. Authenticate using a second mail address.')}</a>
            <div className="TileButtonDiv">
              <button onClick={() => navigate(multiFactorEmailEnabled ? '/MultiFactorDisable' : '/MultiFactorEmail')}>
                {t('Enable')}
              </button>
            </div>
          </form>
        </div>
        <div id="MfaTile">
          <form>
            <div id="BlockHeadLine">
              <h3>{t('App')}</h3>
            </div>
            <img
              src={theme.App}
              alt=""
            />
            <a>{t('2. Authentication via mobile app.')}</a>
            <div className="TileButtonDiv">
              <button onClick={() => navigate(multiFactorAppEnabled ? '/MultiFactorDisable' : '/MultiFactorApp')}>
                {t('Enable')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
}

import '../StyleSheets/main.css';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { ReactivateUser } from './AccountLoader';
import Urls from '../Urls';
import { DefaultToastConfig } from '../ToastConfig';
import { OrganizationHeaders, useOrganizationHeaders } from '../Dashboard';
import { LoadOrganizations } from '../Organisation/OrganisationLoader';

export default function ActivateAccount() {
  const [date, setDate] = useState<Date>(new Date(Date.now()));
  const [, setHeader] = useOrganizationHeaders();
  const navigate = useNavigate();

  async function Reactivate(e?: React.MouseEvent<HTMLButtonElement>) {
    if (e !== undefined)
      e.preventDefault();

    const user = await Auth.currentAuthenticatedUser();
    await ReactivateUser();
    await Auth.deleteUserAttributes(user, ['custom:Inactive']);
    const header = new OrganizationHeaders();
    header.loadingFinished = false;
    setHeader(header);
    toast.info(t('Account has been reactivated'), DefaultToastConfig);
    await LoadOrganizations(setHeader);
    navigate('/');
  }

  useEffect(() => {
    GetDate();
    async function GetDate() {
      const user = await Auth.currentAuthenticatedUser();
      const date = new Date(Date.parse(user.attributes['custom:Inactive']));
      setDate(date);
    }
  }, []);

  return (
    <div id="PageBody">
      <div className="centeredDiv">
        <div id="HeadLine">
          <h2>{t('Your account is inactive')}</h2>
          {t('Your account is inactive due to your deletion request.')}
          {t('You can reactivate your account until {{date}}', { date: date?.toLocaleDateString('de-DE') })} <br />
        </div>
        <form>
          <br />
          <div id="ButtonBox">
            <button type="submit" className="button" onClick={Reactivate}>{t('Reactivate')}</button>
            <button className="button" onClick={async () => { navigate(Urls.Login); await Auth.signOut(); }}>{t('Log out')}</button>
          </div>
          <br />
        </form>
      </div>
    </div>
  );
}

/* eslint-disable max-len */
import { t } from 'i18next';
import '../StyleSheets/main.css';
import Select from 'react-select';
import {
  useEffect, useState, ClipboardEvent, useContext,
} from 'react';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';
import { LanguageSelector } from '../Settings/Language';
import MyDATAflorRoles, { MeasureWebRoles } from '../Settings/Roles';
import i18n from '../../i18n';
import {
  InvalidateInvitation, LoadInvitations, LoadMembers, LoadOrganizations, RemoveMember, SendInvite, SetUserRole, UpdateInvite,
} from './OrganisationLoader';
import {
  ServiceRole, MeasurewebRole, Service, GetRolesByService, GraphQLError,
} from '../Model/Enums';
import OrganizationMember from '../Model/OrganizationMember';
import MemberRole from '../Model/MemberRole';
import Invitation from '../Model/Invitation';
import validateEmail from '../validateEmail';
import { useOrganizationHeaders } from '../Dashboard';
import CheckAdminPermission, { HandleError } from './CheckPermission';
import { DefaultToastConfig } from '../ToastConfig';
import infoIcon from '../Images/info-circle-white.svg';

import { ThemeContext } from '../Images/ThemeContext';
import CustomizedModal from '../Modal';

export default function UserManagement() {
  const [MyDataflorRole, setMyDATAflorRole] = useState<ServiceRole>(ServiceRole.None);
  const [MeasureWebRole, setMeasureWebRole] = useState<MeasurewebRole>(MeasurewebRole.None);
  const [mail, setMail] = useState('');
  const [MailList, MailChipsList] = useState<string[]>([]);
  const [mailLanguage, setMailLanguage] = useState(i18n.language);

  const [isAddingUser, setIsAddingUser] = useState(false);
  const [IsLoadingTable, setIsLoadingTable] = useState(true);
  const [Members, setMembers] = useState<OrganizationMember[]>([]);
  const [Invitations, setInvitations] = useState<Invitation[]>([]);
  const [username, setUsername] = useState('');

  const navigate = useNavigate();
  const [organisationMetaData, setOrganizationHeaders] = useOrganizationHeaders();

  const cantDeleteLastAdminTooltip = t('This user is the last admin of the organization and cannot be removed. Consider deleting the organization instead.');
  const [showWarning, setShowWarning] = useState(false);
  const [removeSelf, setRemoveSelf] = useState(false);
  const [clickedMember, setClickedMember] = useState<OrganizationMember | Invitation>();
  const [overlayLoading, setOverlayLoading] = useState(false);

  const { theme } = useContext(ThemeContext);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 30,
      minHeight: 30,
      width: 200,
      margin: 5,
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 5,
      paddingBottom: 5,
    }),
    clearIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 5,
      paddingBottom: 5,
    }),
  };

  useEffect(() => {
    CheckAdminPermission(navigate, organisationMetaData);
  }, [organisationMetaData]);

  async function Load() {
    setIsLoadingTable(true);
    const user = await Auth.currentAuthenticatedUser();
    setUsername(user.username);
    setMembers(await LoadMembers());
    setInvitations(await LoadInvitations());
    setIsLoadingTable(false);
  }
  useEffect(() => {
    Load();
  }, []);

  function prepareInvite(e: any) {
    e?.preventDefault();
    // The regex removes all whitespace, using a set removes duplicates.
    setIsAddingUser(true);
    MailList.forEach(async (element) => {
      if (Members.some((x) => x.User?.CognitoEmail === element) || Invitations.some((x) => x.Email === element && !x.Invalidated)) {
        toast.info(t('A user with that email is already in your organization.'), DefaultToastConfig);
        EmptyinviteFields();
        return;
      }
      if (!validateEmail(element)) {
        toast.info(t('Please enter a valid email address.'), DefaultToastConfig);
        EmptyinviteFields();
        return;
      }
      const success = await SendInvite(element, MyDataflorRole, MeasureWebRole, mailLanguage);
      if (success === GraphQLError.AccessDenied)
        HandleError(success, setOrganizationHeaders);
      if (success) {
        toast.success(t('Invitations have been sent.'), {
          position: 'bottom-right',
          autoClose: 3000,
        });
      } else
        toast.error(t('Could not sent invitation.'), {
          position: 'bottom-right',
          autoClose: 3000,
        });
      Load();
      EmptyinviteFields();
    });
  }
  function EmptyinviteFields() {
    setMail('');
    setMyDATAflorRole(ServiceRole.None);
    setMeasureWebRole(MeasurewebRole.None);
    setIsAddingUser(false);
    MailChipsList([]);
  }

  function areRolesDirty() {
    return (MyDataflorRole !== ServiceRole.None || MeasureWebRole !== MeasurewebRole.None);
  }

  function isMemberLastAdmin(member: OrganizationMember) : boolean {
    return !Members.filter((x) => x !== member).some((x) => x.MemberRoles?.some((y) => y.Role!.ID === ServiceRole.Admin));
  }
  async function setUser(member: OrganizationMember, role: ServiceRole | MeasurewebRole, service: Service) {
    const response = await SetUserRole(member.User!.CognitoUsername!, role, service);
    if (typeof response === 'number') {
      HandleError(response, setOrganizationHeaders);
      return;
    }
    const memberindex = Members.indexOf(member);
    const newMembers = [...Members];
    const newMember = member;
    newMember.MemberRoles = member.MemberRoles!.filter((x) => !GetRolesByService(service).includes(x.Role!.ID!));
    newMember.MemberRoles.push(new MemberRole({
      Role: { ID: role },
      OrganizationMember: member,
    }));
    newMembers[memberindex] = newMember;
    setMembers(newMembers);
    toast.info(t(role === 0 ? 'Succesfully revoked product role' : 'Successfully assigned product role.'), DefaultToastConfig);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if ([';'].includes(event.key)) {
      event.preventDefault();
      MailChipsList((x) => [...x, mail]);
      setMail('');
    }
  };

  const handlePaste = (e: ClipboardEvent) => {
    e.preventDefault();
    const { clipboardData } = e;
    const pastedText = clipboardData.getData('text');

    // This RegEx checks if there is one or more emails. There getting seperated and each mail get an chip
    // eslint-disable-next-line no-useless-escape
    const emails = pastedText.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      const toBeAdded = emails.filter((email) => !MailList.includes(email));

      MailChipsList((x) => [...x, ...toBeAdded]);
    }
    setMail('');
  };

  const deleteMail = (item: string) => {
    const newMailList = MailList.filter((x) => x !== item);
    MailChipsList(newMailList);
  };

  const MailChips = MailList.map((mail: string) => (
    <div id="MailChip" key={mail}>
      {mail}
      <button onClick={() => deleteMail(mail)}>
        <img
          src={theme.XCircle}
          alt=""
        />
      </button>
    </div>
  ));

  const handleOnBlur = () => {
    // This RegEx checks if there is one or more emails. There getting seperated and each mail get an chip
    // eslint-disable-next-line no-useless-escape
    const emails = mail.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (emails) {
      const toBeAdded = emails.filter((email) => !MailList.includes(email));

      MailChipsList((x) => [...x, ...toBeAdded]);
    }
    setMail('');
  };

  const userlist = Members.map((member: OrganizationMember) => (
    <tr key={member.Guid}>
      <td id="tdItem">{`${member.User?.Surname ?? ''} ${member.User?.LastName ?? ''}`}</td>
      <td id="tdItem">{member.User!.CognitoEmail}</td>
      <td id="tdItem">{t('Active')}</td>
      <td id="tdItem">
        <div id="Services">
          <a>{t('Organization administration')}</a>
          <a>{t('MeasureWeb')}</a>
        </div>
      </td>
      <td>
        <div id="UserListRoles">
          <Select
            styles={customStyles}
            value={MyDATAflorRoles().filter((option) => member.MemberRoles!.some((x : MemberRole) => x.Role!.ID === option.value))}
            options={MyDATAflorRoles().filter((option) => option.value !== ServiceRole.None || member.MemberRoles?.find((x) => x.Role?.ID === MeasurewebRole.User))}
            onChange={(x) => { setUser(member, x!.value, Service.SelfService); }}
            isDisabled={member.User?.CognitoUsername === username}
          />
        </div>
        <div id="UserListRoles">
          <Select
            styles={customStyles}
            value={MeasureWebRoles().filter((option) => option.value === MeasurewebRole.None || member.MemberRoles!.some((x : MemberRole) => x.Role!.ID === option.value))}
            options={MeasureWebRoles()}
            onChange={(x) => { setUser(member, x!.value, Service.MeasureWeb); }}
          />
        </div>
      </td>
      <td>
        <div data-tip={isMemberLastAdmin(member) ? t(cantDeleteLastAdminTooltip) : t('Delete user')}>
          <button
            id="UserManagementButton"
            disabled={isMemberLastAdmin(member)}
            onClick={async (e) => {
              e?.preventDefault();
              setClickedMember(member);
              const user = await Auth.currentAuthenticatedUser();
              setRemoveSelf(member?.User?.CognitoEmail === user.attributes.email);
              setShowWarning(true);
            }}
          >
            <img
              src={theme.XCircle}
              alt=""
            />
          </button>
        </div>
        <ReactTooltip />
      </td>
    </tr>
  ));
  async function SendUpdate(newInvitation: Invitation, revoked: boolean) {
    const response = await UpdateInvite(newInvitation);
    if (typeof response === 'number') {
      HandleError(response, setOrganizationHeaders);
      return;
    }
    const message = revoked ? t('Successfully revoked product role') : t('Successfully assigned product role');
    toast.info(message, DefaultToastConfig);
    setInvitations(await LoadInvitations());
  }

  const invitationsList = Invitations.filter((x) => (x.Invalidated !== 'true') && !Members.some((y) => y.User?.CognitoEmail === x.Email))
    .map((invitation: Invitation) => (
      <tr key={invitation.ID}>
        <td />
        <td id="tdItem">{invitation.Email}</td>
        <td id="tdItem">{ t('Pending')}</td>
        <td id="tdItem">
          <div id="Services">
            <a>{t('Organization administration')}</a>
            <a>{t('MeasureWeb')}</a>
          </div>
        </td>

        <td>
          <div id="UserListRoles">
            <Select
              styles={customStyles}
              value={MyDATAflorRoles().filter((option) => option.value.toString() === invitation.MyDataflorID)}
              options={MyDATAflorRoles()}
              onChange={async (event : any) => {
                const newInvitation = { ...invitation };
                newInvitation.MyDataflorID = event?.value.toString();
                SendUpdate(newInvitation, newInvitation.MyDataflorID === '0');
              }}
            />
          </div>
          <div id="UserListRoles">
            <Select
              styles={customStyles}
              value={MeasureWebRoles().filter((option) => option.value.toString() === invitation.MeasureWebID)}
              options={MeasureWebRoles()}
              onChange={async (x) => {
                const newInvitation = new Invitation({ ...invitation, MeasureWebID: x?.value.toString() });
                SendUpdate(newInvitation, newInvitation.MeasureWebID === '0');
              }}
            />
          </div>
        </td>
        <td>
          <button id="UserManagementButton" data-tip={t('Cancel invitation')} onClick={async (e) => { e?.preventDefault(); setClickedMember(invitation); setRemoveSelf(false); setShowWarning(true); }}>
            <img
              src={theme.XCircle}
              alt=""
            />
          </button>
          <ReactTooltip />
        </td>
      </tr>
    ));
  // eslint-disable-next-line max-len
  function getWarning() : string {
    let warning = '';
    let name = '';
    // eslint-disable-next-line max-len
    const removeUserPrompt = 'You are removing the user {{username}}.The user will be removed from your organization and no longer have access to the organization or its services. Do you want to Continue?';
    if ((clickedMember instanceof OrganizationMember)) {
      warning = removeSelf
        ? t('You are removing yourself from the organization. You will no longer be able to manage users or access any of the organization-specific data and services.')
        : removeUserPrompt;
      name = `${(clickedMember?.User?.Surname ?? '')} ${clickedMember?.User?.LastName ?? ''}`;
    }
    if ((clickedMember instanceof Invitation)) {
      // eslint-disable-next-line max-len
      name = clickedMember?.Email ?? '';
      warning = t('You are removing user {{username}}. The invitation will be canceled. The user has no longer access to the organization or its services. Do you want to Continue?', { username: name });
    }
    return warning;
  }

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('User Management')}</h2>
        <a>{t('Add new users and manage the access to different service by giving roles and permissions to each user')}</a>
      </div>
      <div id="UserManagement">
        <div id="AddUser">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('Add another user')}</h3>
            </div>
            <div className="InputWithToolTip">
              <a className="boldInputHeadline">{t('New Email address')}</a>
              <a id="ToolTip" data-tip={t('You can add multiple people at the same time. Separate the email-addresses using a semicolon. Note, we can’t send invitations to distribution lists.')}>
                <img
                  src={infoIcon}
                  alt=""
                />
              </a>
              <ReactTooltip />
            </div>
            <input className="input" type="text" placeholder="mail@example.com" value={mail} onBlur={handleOnBlur} onPaste={handlePaste} onChange={(x) => { setMail(x.target.value); }} onKeyDown={handleKeyDown} />
            <div id="MailChips">
              {MailChips}
            </div>
            <a className="boldInputHeadline" id="Products">{t('Product roles')}</a>
            <div id="AddUserService">
              <div id="Service">
                <a>{t('Organization administration')}</a>
              </div>
              <div>
                <Select
                  styles={customStyles}
                  value={MyDATAflorRoles().filter((option) => option.value === MyDataflorRole)}
                  options={MyDATAflorRoles()}
                  onChange={(x) => { setMyDATAflorRole(x!.value); }}
                />
              </div>
            </div>
            <div id="AddUserService">
              <div id="Service">
                <a>{t('MeasureWeb')}</a>
              </div>
              <div>
                <Select
                  styles={customStyles}
                  value={MeasureWebRoles().filter((option) => option.value === MeasureWebRole)}
                  options={MeasureWebRoles()}
                  onChange={(x) => { setMeasureWebRole(x!.value); }}
                />

              </div>
            </div>
            <a className="boldInputHeadline" id="InviteLanguage">{t('Language for invitation Email')}</a>
            <div id="divLanguageAccount">
              <LanguageSelector LanguageChangedCallback={setMailLanguage} />
            </div>
            <button
              type="submit"
              className="button"
              onClick={prepareInvite}
              disabled={(MailList.length === 0 || !areRolesDirty()) || isAddingUser}
            >
              {isAddingUser && <i className="loader" />}
              {t('Add User')}
            </button>
          </form>
        </div>
        <div id="UserList">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('Userlist')}</h3>
            </div>
            <table>
              <tbody>
                <tr id="TableHeadLine">
                  <th>{t('Name')}</th>
                  <th>{t('E-Mail')}</th>
                  <th>{t('Status')}</th>
                  <th>{t('Service')}</th>
                  <th>{t('Roles')}</th>
                </tr>
                {userlist}
                {invitationsList}
              </tbody>
            </table>
            {IsLoadingTable && <i className="UserListloader" />}
            <CustomizedModal
              show={showWarning}
              onHide={() => setShowWarning(false)}
            >
              <div id="PageBody">
                <div id="ModalBody">
                  <img
                    src={theme.DATAflorLogo}
                    alt="Logo"
                    id="Modalimg"
                  />
                  <div id="ModalHeadLine">
                    <h3>{getWarning()}</h3>
                  </div>
                  <div id="inline">
                    <button
                      onClick={async () => {
                        setOverlayLoading(true);
                        let response;
                        if (clickedMember instanceof OrganizationMember) {
                          response = await RemoveMember(clickedMember.Guid!.toString());
                          Invitations.filter((x) => x.Email === clickedMember?.User?.CognitoEmail).forEach(async (x) => { await InvalidateInvitation(x.ID!); });
                        }
                        if (removeSelf) {
                          LoadOrganizations(setOrganizationHeaders);
                          navigate('/OrganizationList');
                        }
                        if (clickedMember instanceof Invitation) {
                          response = await InvalidateInvitation(clickedMember.ID!);
                        }
                        if (typeof response === 'number') {
                          HandleError(response, setOrganizationHeaders);
                          setOverlayLoading(false);
                          return;
                        }
                        await Load();
                        setOverlayLoading(false);
                        setShowWarning(false);
                      }}
                    >
                      {overlayLoading && <i className="loader" />}
                      {t('Continue')}
                    </button>
                    <button onClick={() => setShowWarning(false)}>
                      {t('Cancel')}
                    </button>
                  </div>
                </div>
              </div>
            </CustomizedModal>
          </form>
        </div>
      </div>
    </div>
  );
}

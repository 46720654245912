import '../StyleSheets/main.css';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DeactivateOrganization, LoadOrganizations } from './OrganisationLoader';
import { DefaultToastConfig, FixedToast } from '../ToastConfig';
import { useOrganizationHeaders } from '../Dashboard';
import CheckAdminPermission from './CheckPermission';
import { GraphQLError } from '../Model/Enums';

export default function DeleteOrganization() {
  const navigate = useNavigate();
  const [organizationHeader, setOrganizationHeaders] = useOrganizationHeaders();
  const [organizationName, setorganizationName] = useState<string>();

  async function Delete(e?: React.MouseEvent<HTMLButtonElement>) {
    e?.preventDefault();
    if (organizationName?.trim() !== organizationHeader.currentOrganisationName?.trim()) {
      toast.error(t('Organization name not matching. Please try again.'), DefaultToastConfig);
      return;
    }

    const response = await DeactivateOrganization();
    if (response === GraphQLError.AccessDenied) {
      toast.info(t('Missing Permission.'), DefaultToastConfig);
      navigate('/');
      return;
    }
    toast.info(t('Your organization has been set inactive and will be deleted in 7 days.'), FixedToast);
    navigate('/OrganizationList');
    LoadOrganizations(setOrganizationHeaders);
  }

  useEffect(() => {
    CheckAdminPermission(navigate, organizationHeader);
  }, [organizationHeader]);

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('Do you really want to delete your organization?')}</h2>
        <a>{t('You lose access to all related DATAflor services and we permanently delete your data. You can cancel the deletion within 7 days.')}</a>
      </div>
      <div id="OrganizationDelete">
        <form id="DeleteOrgForm">
          <a>
            {t('Please type in the name of the organization for confirmation.')}
          </a>
          <input id="singleInput" className="input" onChange={(e) => setorganizationName(e.target.value)} />
          <div>
            <button id="rowButton" type="submit" onClick={Delete}>{t('Delete')}</button>
            <button id="rowButton" onClick={() => navigate('/Organization')}>{t('Cancel')}</button>
          </div>
        </form>

      </div>
    </div>
  );
}

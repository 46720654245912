import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useSession } from './Loginpage';
import SetLoginRedirect from './RedirectHelper';
import '../StyleSheets/main.css';
import '../StyleSheets/login.css';

export default function SecondFactorChallenge() {
  const navigate = useNavigate();
  const [oneTimePassword, setoneTimePassword] = useState('');
  const [session, setSession] = useSession();
  const [isLoginEnabled, setIsLoginEnabled] = useState(true);
  const prompt = session.loginSession.challengeName === 'CUSTOM_CHALLENGE'
    ? t('Please enter the one time password, we sent to your two factor mail address.')
    : t('Please enter the one time password, using your authenticator app.');

  async function onLogin(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setIsLoginEnabled(false);
    try {
      let response = '';
      if (session.loginSession.challengeName === 'CUSTOM_CHALLENGE') {
        response = await Auth.sendCustomChallengeAnswer(session.loginSession, oneTimePassword);
        session.loginSession = response;
        if (session.service)
          await SetLoginRedirect(session, setSession, null);
        if (session.loginSession.signInUserSession === null)
          ShowError('login failed');
        else
          session.Redirect(navigate);
      }
      if (session.loginSession.challengeName === 'SOFTWARE_TOKEN_MFA') {
        if (!session.service) {
          response = await Auth.confirmSignIn(session.loginSession, oneTimePassword, session.loginSession.challengeName);
          await Auth.currentAuthenticatedUser();
        } else {
          await SetLoginRedirect(session, setSession, oneTimePassword);
        }
        session.Redirect(navigate);
      }
      console.log(`challenged${response}`);
    } catch (error) {
      ShowError(error);
    }
  }
  function ShowError(error: unknown) {
    setIsLoginEnabled(true);
    console.error(error);
    toast.error(t('Your code is incorrect. Please check your code and try again.'), {
      position: 'bottom-right',
      autoClose: 3000,
    });
  }
  return (
    <div id="loginbox">
      <div id="login-body">
        <span id="single-text">{prompt}</span>
        <form>
          <input id="loginbox-input" autoFocus type="text" onChange={(x) => setoneTimePassword(x.target.value)} placeholder="Code" />
          <button id="loginbox-button" type="submit" className="basicButton" onClick={onLogin} disabled={!isLoginEnabled}>
            {!isLoginEnabled && <i className="loader" />}
            {t('Login')}
          </button>
        </form>
      </div>
    </div>
  );
}

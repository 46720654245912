import { Auth } from 'aws-amplify';
import imageCompression from 'browser-image-compression';
import { themeIcons } from '../Images/Theme';
import '../StyleSheets/main.css';

const compressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export async function UploadAvatar(file: File, callBack:() => void) {
  const user = await Auth.currentAuthenticatedUser();

  const compressedAvatar = file.type === 'image/svg'
    ? file
    : await imageCompression(file, compressionOptions);
  const reader = new FileReader();
  reader.readAsDataURL(compressedAvatar);
  reader.onloadend = UploadAvatar;

  async function UploadAvatar(this: FileReader) {
    const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        mutation updateAvatar($accessKey: ID!, $avatar: ID!) { 
          updateAvatar(accessKey: $accessKey, avatar: $avatar)
        }
        `,
        variables: {
          accessKey: user.signInUserSession.accessToken.jwtToken,
          avatar: this.result,
        },
      }),
    });
    callBack();
    return response.json();
  }
}

export async function getAvatar(theme: typeof themeIcons.dark, Name: string) {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        query getAvatar($accessKey: ID!) { 
          getAvatar(accessKey: $accessKey)
        }
        `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
      },
    }),
  });
  const json = await response.json();
  const s3url = json.data.getAvatar;
  if (!s3url)
    return GetDefaultImage(Name, theme, 'User');

  let getImageResponse: any = {};
  try {
    getImageResponse = await fetch(s3url, {
      method: 'GET',
      headers: {},
    });
    // eslint-disable-next-line no-empty
  } catch (error) { }
  if (getImageResponse.status !== 200)
    return GetDefaultImage(Name, theme, 'User');
  const blob = await getImageResponse.blob();
  if (!imageTypes.includes(blob.type)) {
    return GetDefaultImage(Name, theme, 'User');
  }
  return URL.createObjectURL(blob);
}

export async function UploadOrganizationLogo(file: File, callBack:() => void, ID: string) {
  const user = await Auth.currentAuthenticatedUser();

  const compressedLogo = file.type === 'image/svg'
    ? file
    : await imageCompression(file, compressionOptions);
  const reader = new FileReader();
  reader.readAsDataURL(compressedLogo);
  reader.onloadend = UploadLogo;

  async function UploadLogo(this: FileReader) {
    const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: `
        mutation updateOrganizationLogo($accessKey: ID!, $organization: ID!, $avatar: ID!) { 
          updateOrganizationLogo(accessKey: $accessKey, organization: $organization, avatar: $avatar)
        }
        `,
        variables: {
          accessKey: user.signInUserSession.accessToken.jwtToken,
          avatar: this.result,
          organization: ID,
        },
      }),
    });
    callBack();
    return response.json();
  }
}

export async function GetOrganizationLogo(ID: string, theme: typeof themeIcons.dark, Name: string) {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        query getOrganizationLogo($accessKey: ID!, $organization: ID!) { 
          getOrganizationLogo(accessKey: $accessKey, organization: $organization)
        }
        `,
      variables: {
        accessKey: user.signInUserSession.accessToken.jwtToken,
        organization: ID,
      },
    }),
  });
  const json = await response.json();
  const s3url = json.data.getOrganizationLogo;

  if (!s3url)
    return GetDefaultImage(Name, theme, 'Organization');

  let getImageResponse: any = {};
  try {
    getImageResponse = await fetch(s3url, {
      method: 'GET',
      headers: {},
    });
  // eslint-disable-next-line no-empty
  } catch (error) { }
  if (getImageResponse.status !== 200)
    return GetDefaultImage(Name, theme, 'Organization');
  const blob = await getImageResponse.blob();
  if (!imageTypes.includes(blob.type)) {
    return GetDefaultImage(Name, theme, 'Organization');
  }
  return URL.createObjectURL(blob);
}

const createImageFromInitials = (size: any, name: any) => {
  if (name == null) return;
  // eslint-disable-next-line no-param-reassign
  name = getInitials(name);
  // eslint-disable-next-line no-param-reassign

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  canvas.width = size;
  canvas.height = size;

  context.fillStyle = '#ffffff';
  context.fillRect(0, 0, size, size);

  context.fillStyle = '#4e881450';
  context.fillRect(0, 0, size, size);

  context.fillStyle = '#4e8814';
  context.textBaseline = 'middle';
  context.textAlign = 'center';
  context.font = `${size / 2}px Roboto`;
  context.fillText(name, (size / 2), (size / 2) + 5);

  return canvas.toDataURL();
};

const getInitials = (name: string) => {
  let initials;
  const nameSplit = name.split(' ');
  const nameLength = nameSplit.length;
  if (nameLength > 1) {
    initials = nameSplit[0].substring(0, 1)
          + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 2);
  } else return;

  return initials.toUpperCase();
};
function GetDefaultImage(Name: string, theme: typeof themeIcons.dark, type: keyof typeof themeIcons.dark) {
  if (Name.trim() === '') {
    return theme[type];
  }
  return createImageFromInitials(120, Name);
}

const imageTypes = ['image/png', 'image/gif', 'image/jpeg'];

import { Dispatch, SetStateAction } from 'react';
import { Auth } from 'aws-amplify';
import ServiceConfiguration from './ServiceConfiguration';
import awsconfig from '../../aws-exports';
import { Session } from './Loginpage';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
import * as AdvancedSecurity from './amazon-cognito-advanced-security-data.min.js';
import '../StyleSheets/main.css';

export default async function SetLoginRedirect(session: Session, setSession: Dispatch<SetStateAction<Session>>, appToken: string | null) {
  const service = ServiceConfiguration[session.service as keyof typeof ServiceConfiguration];
  if (!service)
    return;
  const newSession = session;
  const asfData = AdvancedSecurity.getData(session.mail, awsconfig.aws_user_pools_id, service.ClientID);
  const json = JSON.stringify(
    {
      clientID: service.ClientID,
      redirect: service.Redirect,
      username: session.mail,
      password: session.password,
      cognitoAsfData: asfData,
      appToken,
    },
  );

  const response = await fetch(
    `${process.env.REACT_APP_BackendApi}/RedirectUrl`,
    { method: 'POST', headers: { 'content-type': 'application/json' }, body: json },
  );
  const result = await (response.text());
  if (result.includes('amazoncognito'))
    throw new Error(`Invalid Credentials${result}`);
  newSession.RedirectUrl = result;
  setSession(newSession);
}

export function ConfigureService(serviceKey: string, localRedirect?: boolean) {
  const service = ServiceConfiguration[serviceKey as keyof typeof ServiceConfiguration];
  if (service === undefined)
    return;

  console.log(`configuring service: ${service.Redirect}`);
  awsconfig.aws_user_pools_web_client_id = service.ClientID!;
  awsconfig.oauth.redirectSignIn = localRedirect ? ServiceConfiguration.SelfService.Redirect : service.Redirect;
  awsconfig.oauth.redirectSignOut = service.Redirect;
  Auth.configure(awsconfig);
}

export function ProofKeyCodeExchange() : boolean {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const state = params.get('state');
  const pkce = sessionStorage.getItem('ouath_pkce_key');
  if (!state)
    return false;
  const split = state.split('-');
  if (split.length <= 1)
    return false;
  const decodedState = urlSafeDecode(split[1]!);
  type Services = keyof typeof ServiceConfiguration;
  if (!decodedState || !(decodedState as Services) || decodedState === 'SelfService')
    return false;

  const searchParams = new URLSearchParams();
  searchParams.append('code', code!);
  searchParams.append('state', pkce!);
  const redirect = `${ServiceConfiguration[decodedState as keyof typeof ServiceConfiguration].Redirect}?${searchParams.toString()}`;

  window.location.href = redirect;
  return true;
}

// This function is copied from the amplify sdk
export function urlSafeDecode(hex: string) {
  return hex
    .match(/.{2}/g)!
    .map((char) => String.fromCharCode(parseInt(char, 16)))
    .join('');
}

export function urlSafeEncode(str: string) {
  return str
    .split('')
    .map((char) => char
      .charCodeAt(0)
      .toString(16)
      .padStart(2, '0'))
    .join('');
}

export enum ServiceRole{
  Admin = 1,
  None = 0
}

export enum MeasurewebRole{
  User = 2,
  None = 0
}

export enum Service{
  SelfService = 'SelfService',
  MeasureWeb = 'MeasureWeb'
}

export enum GraphQLError {
  AccessDenied,
  MissingArgument,
  MaxOrganizations,
  DataNotFound,
  Unknown,
}

export function GetRolesByService(service: Service) {
  switch (service) {
    case Service.SelfService:
      return [0, 1];
    case Service.MeasureWeb:
      return [0, 2];
    default:
      return [];
  }
}

export function GetRoleByID(id: number) {
  switch (id) {
    case 0:
      return ServiceRole.None;
    case 1:
      return ServiceRole.Admin;
    case 2:
      return MeasurewebRole.User;
    default:
      return ServiceRole.None;
  }
}

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import '../StyleSheets/main.css';
import validateEmail from '../validateEmail';
import EmailCodeValidationOverlay from './EmailCodeValidationOverlay';
import i18n from '../../i18n';
import CustomizedModal from '../Modal';

export default function MultiFactorEmail() {
  const [showOverlay, setShow] = useState(false);

  const [multiFactorMail, setMultiFactorMail] = useState('');

  async function onSaveMail(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    if (!showFeedback(multiFactorMail, user))
      return;
    const token = user.signInUserSession.accessToken.jwtToken;
    const json = JSON.stringify(
      {
        mail: multiFactorMail,
        multifactor: true,
        locale: i18n.language,
        mailtype: 'verificationCode',
        jwt: token,
      },
    );
    const mailPost = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: json,
    };
    fetch(`${process.env.REACT_APP_BackendApi}/VerificationEmail`, mailPost)
      .catch((err) => console.log(`Error while posting Multi Factor Email:${err}`));
    setShow(true);
  }

  function showFeedback(email: string, user: any) {
    if (!validateEmail(email)) {
      toast.error(t('Please enter a valid email address.'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }
    if (user.attributes.email === email) {
      toast.error(t('The two factor mail address must be different from the login mail address.'), {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      return false;
    }
    return true;
  }

  return (
    <div className="PageBody">
      <div id="HeadLine">
        <h2>{t('Two factor authentication via email.')}</h2>
        <a>{t('For two factor authentication via email please enter the desired address and press send.')}</a>
        <a>{t('This email must be different from the email address used for login.')}</a>
      </div>
      <div id="mfaEmail">
        <form>
          <input
            id="mfaInput"
            className="input"
            value={multiFactorMail}
            onChange={(x) => { setMultiFactorMail(x.target.value); }}
            placeholder={t('E-Mail')}
          />
          <button
            type="submit"
            onClick={onSaveMail}
            className="button"
          >
            {t('Send')}
          </button>
        </form>
      </div>
      <CustomizedModal
        show={showOverlay}
        onHide={() => setShow(false)}
      >
        <EmailCodeValidationOverlay closeOverlay={() => { setShow(false); }} multifactor />
      </CustomizedModal>
    </div>
  );
}

import {
  useEffect, useRef, useCallback, useState, useContext,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavigationBlocker from './useBlocker';
import '../StyleSheets/main.css';
import { ThemeContext } from '../Images/ThemeContext';
import CustomizedModal from '../Modal';

// Shows a warning beforeunload or when navigating within the site.
// Required params:
// dirtyFlags: array of boolean state variables, are checked to see if a warning should be shown
// SaveCallback: a function that is called, when the user decides to save in the overlay
export default function UnsavedChangesWarning(props: { dirtyFlags: boolean[]; }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [showCustomCheck, setshowCustomCheck] = useState(false);

  const [navigatedPath, setnavigatedPath] = useState('');
  const userAllowedNavigation = useRef(false);
  const { theme } = useContext(ThemeContext);

  const ShowUnloadWarning = useRef(false);
  function unloadListener(e : any) {
    if (!ShowUnloadWarning.current) {
      return;
    }

    const confirmationMessage = '/o';
    (e || window.event).returnValue = confirmationMessage; // Gecko + IE
    // eslint-disable-next-line consistent-return
    return confirmationMessage; // Webkit, Safari, Chrome
  }

  // The variables passed to the Beforeunload Eventhandler need to be mutable, so useRef()
  // and keep the reference updated.
  useEffect(() => {
    ShowUnloadWarning.current = props.dirtyFlags.some((x) => x);
  }, [props.dirtyFlags]);

  // Register the handler for when the user tries to leave the entire site
  useEffect(() => {
    window.addEventListener('beforeunload', unloadListener);
  }, []);

  // This callback is run during attemps to navigate from the page
  const blocker = useCallback((tx) => {
    if (location.pathname === tx.location.pathname)
      return;
    setshowCustomCheck(true);
    setnavigatedPath(tx.location.pathname);
    if (userAllowedNavigation.current)
      tx.retry();
    userAllowedNavigation.current = false;
  }, [userAllowedNavigation, location]);
  NavigationBlocker(blocker, (props.dirtyFlags.some((x) => x)));

  function leave() {
    userAllowedNavigation.current = true;
    navigate(navigatedPath);
    setshowCustomCheck(false);
  }

  const { t } = useTranslation();
  return (
    <CustomizedModal
      show={showCustomCheck}
      aria-labelledby="modal-label"
    >
      <div id="PageBody">
        <div id="ModalBody">
          <img
            src={theme.DATAflorLogo}
            alt="Logo"
            id="Modalimg"
          />
          <a>{t('saveChanges')}</a>
          <div id="inline">
            <button onClick={() => { setshowCustomCheck(false); }}>{t('Stay on page')}</button>
            <button onClick={leave}>{t('leave')}</button>
          </div>
        </div>
      </div>
    </CustomizedModal>
  );
}

/* eslint-disable prefer-template */
import { t } from 'i18next';
import './StyleSheets/main.css';
import { useNavigate } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import ReactTooltip from 'react-tooltip';
import { useOrganizationHeaders } from './Dashboard';
import { IsMemberAdmin } from './Settings/OrganisationMenuConfiguration';
import { ThemeContext } from './Images/ThemeContext';
import { getAvatar, GetOrganizationLogo } from './Settings/AvatarRequests';
import { UserContext } from './Model/UserContext';

export default function LandingPage() {
  const [organizationMetaData] = useOrganizationHeaders();
  const [denyOrganizationAccess, setDenyOrganizationAccess] = useState(true);

  const [organizationLogo, setOrganizationLogo] = useState<string>();
  const [usermail, setUserMail] = useState();
  const [username, setUserName] = useState('');
  const { theme } = useContext(ThemeContext);
  const {
    userData, AvatarImage, setAvatar,
  } = useContext(UserContext);

  async function Load() {
    if (!organizationMetaData.loadingFinished)
      return;
    getAvatar(theme, (userData.Surname ?? '') + ' ' + (userData.LastName ?? '')).then((x) => setAvatar(x));
    const user = await Auth.currentAuthenticatedUser();
    GetOrganizationLogo(user.attributes['custom:CurrentOrganization'], theme, organizationMetaData.currentOrganisationName!).then((x) => setOrganizationLogo(x));
    setUserName((userData.Surname ?? '') + ' ' + (userData.LastName ?? ''));
    setDenyOrganizationAccess(!IsMemberAdmin(organizationMetaData.organisationMetaData!, user.attributes['custom:CurrentOrganization'])!);
    setUserMail(user.attributes.email);
  }
  useEffect(() => { Load(); }, [organizationMetaData]);

  const navigate = useNavigate();
  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('Welcome to MyDATAflor portal')}</h2>
      </div>
      <div id="LandingPage">
        <div>
          <div id="TileGroupHeader">
            <button id="profile">
              <img
                src={organizationLogo}
                alt=""
              />
              {organizationMetaData.currentOrganisationName} <br />
            </button>
          </div>
          <div id="TileGroup">
            <div id="LandingPageTile">
              <form>
                <div id="BlockHeadLine">
                  <h3>{t('Your Organization')}</h3>
                </div>
                <img
                  src={theme.Building}
                  alt=""
                />
                <a>{t('Manage the information about your organization, add contact information and social media accounts.')}</a>
                <div className="TileButtonDiv" data-tip={denyOrganizationAccess ? t('Missing Permission.') : undefined}>
                  <button disabled={denyOrganizationAccess} onClick={() => navigate('Organization')}>
                    {t('Organization settings')}
                  </button>
                </div>
                <ReactTooltip />
              </form>
            </div>
            <div id="LandingPageTile">
              <form>
                <div id="BlockHeadLine">
                  <h3>{t('Invite User')}</h3>
                </div>
                <img
                  src={theme.PersonPlus}
                  alt=""
                />
                <a>{t('Add new users and manage roles and permissions to each user')}</a>
                <div className="TileButtonDiv" data-tip={denyOrganizationAccess ? t('Missing Permission.') : undefined}>
                  <button disabled={denyOrganizationAccess} data-tip={denyOrganizationAccess ? t('Missing Permission.') : undefined} onClick={() => navigate('Users')}>
                    {t('User management')}
                  </button>
                </div>
                <ReactTooltip />
              </form>
            </div>
          </div>
        </div>
        <div>
          <div id="TileGroupHeader">
            <button id="profile">
              <img
                src={AvatarImage}
                alt=""
              />
              {username} <br />
              {usermail}
            </button>
          </div>
          <div id="TileGroup">
            <div id="LandingPageTile">
              <form>
                <div id="BlockHeadLine">
                  <h3>{t('Edit you Profile here')}</h3>
                </div>
                <img
                  src={theme.Person}
                  alt=""
                />
                <a>{t('Manage your personal information, add contact information and social media accounts')}</a>
                <div className="TileButtonDiv">
                  <button onClick={() => navigate('Profile')}>
                    {t('profile')}
                  </button>
                </div>
              </form>
            </div>
            <div id="LandingPageTile">
              <form>
                <div id="BlockHeadLine">
                  <h3>{t('Secure your account')}</h3>
                </div>
                <img
                  src={theme.ShieldLock}
                  alt=""
                />
                <a>{t('Change your login credentials, your language or activate multi-factor authentication for the login')}</a>
                <div className="TileButtonDiv">
                  <button onClick={() => navigate('Account')}>
                    {t('account')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

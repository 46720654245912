/* eslint-disable prefer-template */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import * as localCountry from 'i18n-iso-countries';
import { toast } from 'react-toastify';
import i18next, { t } from 'i18next';
import { Auth } from 'aws-amplify';
import '../StyleSheets/main.css';
import Select from 'react-select';
import * as CountryState from 'country-state-city';
import ReactTooltip from 'react-tooltip';
import UnsavedChangesWarning from './UnsavedChangesWarning';
import { CountryData } from '../Model/Organization';
import { LoadCountriesState } from './Language';
import Avatar from './Avatar';
import { getAvatar, UploadAvatar } from './AvatarRequests';
import { ThemeContext } from '../Images/ThemeContext';
import { UserContext } from '../Model/UserContext';
import { CountryCodes } from '../CountryCode';

export default function Profile() {
  // Save dirty state, for enabling save buttons.
  const { theme } = useContext(ThemeContext);
  const [IsProfileDirty, setIsProfileDirty] = useState(false);
  const [IsContactDirty, setIsContactDirty] = useState(false);
  const {
    userData, setAvatar, AvatarImage, refreshUser,
  } = useContext(UserContext);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: 30,
      minHeight: 30,
    }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 5,
      paddingBottom: 5,
    }),
    clearIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 5,
      paddingBottom: 5,
    }),
  };

  const { language } = i18next;
  const country = CountryState.Country.getCountryByCode(userData.Country!);
  const state = CountryState.State.getStateByCodeAndCountry(userData.State!, userData.Country!);

  const [Name, setName] = useState(userData.Surname);
  const [LastName, setLastName] = useState(userData.LastName);
  const [Address, setAddress] = useState(userData.Address);
  const [Postcode, setPostcode] = useState(userData.Address);
  const [City, setCity] = useState(userData.City);
  const [Country, setCountry] = useState<CountryData>({ label: localCountry.getName(country?.isoCode as string, language), value: country?.isoCode });
  const [State, setState] = useState<CountryData>({ label: state?.name, value: state?.isoCode });
  const [ContactMail, setContactMail] = useState(userData.ContactEmail);
  const [CountryCode, setCountryCode] = useState('+49');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [xNK, setxNK] = useState(userData.Xing);
  const [LinkedIn, setLinkedIn] = useState(userData.LinkedIn);

  useEffect(
    () => {
      LoadCountriesState(Country?.value, setCountries, setStates);
    },
    [],
  );

  function SplitPhoneNumber(phoneNumber: string) {
    if (phoneNumber.startsWith('+')) {
      const PhoneNumberSplit = phoneNumber.split('/');
      setCountryCode(PhoneNumberSplit[0]);
      return PhoneNumberSplit[1];
    }
    setCountryCode('+49');
    return '';
  }

  useEffect(() => {
    if (Country && Country.value)
      setStates(CountryState.State.getStatesOfCountry(Country!.value!).map((x) => ({ label: t(x.name), value: x.isoCode })));
  }, [Country]);

  useEffect(
    () => {
      LoadData();
    },
    [userData.Surname, userData.Country, userData.State, userData.LastName,
      userData.ContactEmail, userData.PhoneNumber, userData.Xing, userData.LinkedIn,
      userData.Address, userData.PostCode, userData.City],
  );

  async function LoadData() {
    const country = CountryState.Country.getCountryByCode(userData.Country!);
    const state = CountryState.State.getStateByCodeAndCountry(userData.State!, userData.Country!);
    setName(userData.Surname!);
    setLastName(userData.LastName!);
    setContactMail(userData.ContactEmail!);
    setPhoneNumber(SplitPhoneNumber(userData.PhoneNumber!));
    setxNK(userData.Xing!);
    setLinkedIn(userData.LinkedIn!);
    setAddress(userData.Address!);
    setPostcode(userData.PostCode!);
    setCity(userData.City!);
    setCountry({ label: localCountry.getName(country?.isoCode as string, language), value: country?.isoCode });
    setState({ label: state?.name, value: state?.isoCode });
  }

  const [countries, setCountries] = useState<any>();
  const [states, setStates] = useState<any>();

  const onSaveData = async (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    const user = await Auth.currentAuthenticatedUser();
    await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: 'mutation ($user:UserInput!, $accessKey: ID!){ updateUser(user: $user, accessKey: $accessKey) { CognitoUsername LastName} }',
        variables: {
          user: {
            CognitoUsername: user.username,
            LastName,
            Surname: Name,
            Address,
            PostCode: Postcode,
            City,
            Country: Country?.value,
            State: State?.value,
            ContactEmail: ContactMail,
            PhoneNumber: PhoneNumber === '' ? PhoneNumber : CountryCode + '/' + PhoneNumber,
            Xing: xNK,
            LinkedIn,
          },
          accessKey: user.signInUserSession.accessToken.jwtToken,
        },
      }),
    });
    setIsProfileDirty(false);
    setIsContactDirty(false);
    refreshUser();
    setAvatar(await getAvatar(theme, (Name ?? '') + ' ' + (LastName ?? '')));
    toast.success(t('Changes saved succesfully'), {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('profile settings')}</h2>
        <a>{t('Manage your personal information and add contact information and social media accounts')}</a>
      </div>
      <br />
      <br />
      <div id="ProfileSettings">
        <div className="TileBody" id="AvatarEditor">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('Avatar')}</h3>
            </div>
            <Avatar UploadAvatar={(image, callback) => { UploadAvatar(image, async () => { callback(); setAvatar(await getAvatar(theme, (userData.Surname + ' ' + userData.LastName))); }); }} getAvatar={() => AvatarImage} />
          </form>
        </div>
        <div className="TileBody" id="AboutYou">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('aboutYou')}</h3>
            </div>
            <a id="inputHeadline">{t('name')}</a>
            <input className="input" type="text" autoFocus onChange={(e) => { setName(e.target.value); setIsProfileDirty(true); }} defaultValue={Name} />
            <a id="inputHeadline">{t('lastname')}</a>
            <input className="input" type="text" autoFocus onChange={(e) => { setLastName(e.target.value); setIsProfileDirty(true); }} defaultValue={LastName} />
            <a id="inputHeadline">{t('Country')}</a>
            <Select styles={customStyles} options={countries} className="inputSelect" onChange={(e: any) => { setCountry(e); setIsProfileDirty(true); }} value={Country} />
            <a id="inputHeadline">{t('State')}</a>
            <Select styles={customStyles} options={states} className="inputSelect" onChange={(e: any) => { setState(e); setIsProfileDirty(true); }} value={State} />
            <a id="inputHeadline">{t('postcode')}</a>
            <input className="input" type="text" onChange={(e) => { setPostcode(e.target.value); setIsProfileDirty(true); }} defaultValue={Postcode} />
            <a id="inputHeadline">{t('city')}</a>
            <input className="input" type="text" onChange={(e) => { setCity(e.target.value); setIsProfileDirty(true); }} defaultValue={City} />
            <a id="inputHeadline">{t('street')}</a>
            <textarea className="input" rows={5} onChange={(e) => { setAddress(e.target.value); setIsProfileDirty(true); }} defaultValue={Address} />
            <button type="submit" className="button" onClick={onSaveData} disabled={!IsProfileDirty}>
              {t('save')}
            </button>
          </form>
        </div>
        <div className="TileBody" id="Contact">
          <form className="TileForm">
            <div id="BlockHeadLine">
              <h3>{t('contact')}</h3>
            </div>
            <a id="inputHeadline">{t('email')}</a>
            <input className="input" type="text" onChange={(e) => { setContactMail(e.target.value); setIsContactDirty(true); }} defaultValue={ContactMail} />
            <a id="inputHeadline">{t('phone')}</a>
            <div id="PhoneInput">
              <Select
                isSearchable={false}
                styles={customStyles}
                options={CountryCodes()}
                onChange={(e: any) => { setCountryCode(e.value); setIsContactDirty(true); }}
                value={CountryCodes().filter((option) => option.value === CountryCode)}
              />
              <input className="input" type="text" onChange={(e) => { setPhoneNumber(e.target.value); setIsContactDirty(true); }} defaultValue={PhoneNumber} />
            </div>
            <a id="inputHeadline">Xing</a>
            <input
              className="input"
              data-tip={t('Add a link to your profile.<br> You can find the link to your Xing-profile in the adress bar of your browser, while on your profile page.<br> Usually https://www.xing.com/profile/JohnDoe')}
              type="text"
              placeholder={`https://www.xing.com/profile/${t('johndoe')}`}
              onChange={(e) => { setxNK(e.target.value); setIsContactDirty(true); }}
              defaultValue={xNK}
            />
            <ReactTooltip multiline />
            <a id="inputHeadline">LinkedIn</a>
            <input
              className="input"
              data-tip={t("Log into LinkedIn and do the following. <br> At the top of LinkedIn's homepage click on your profile image.<br> Click on View profile. <br> On the right side of your profile page choose Edit public profile and Url. <br> Find your Url on the right side of the Edit Custom Url page found in your public profile.")}
              type="text"
              placeholder={`https://linkedin.com/in/${t('johndoe')}`}
              onChange={(e) => { setLinkedIn(e.target.value); setIsContactDirty(true); }}
              defaultValue={LinkedIn}
            />
            <ReactTooltip multiline />
            <button type="submit" className="button" onClick={onSaveData} disabled={!IsContactDirty}>
              {t('save')}
            </button>
          </form>
        </div>
      </div>
      <UnsavedChangesWarning dirtyFlags={[IsProfileDirty, IsContactDirty]} />
    </div>
  );
}

/* eslint-disable import/prefer-default-export */
import LogoWhite from './white/DATAflor_Logo.svg';
import AppWhite from './white/App-white.svg';
import BasketWhite from './white/basket-white.svg';
import BuildingWhite from './white/building-white.svg';
import DoorOpenWhite from './white/door-open-white.svg';
import EmailAuthWhite from './white/Email-authent-white.svg';
import EmailWhite from './white/Email-white.svg';
import GearWhite from './white/gear-white.svg';
import GridWhite from './white/grid-white.svg';
import HouseWhite from './white/house-white.svg';
import ListWhite from './white/list-white.svg';
import OrganizationWhite from './white/Organization-white.svg';
import PersonPlusWhite from './white/person-plus-white.svg';
import PersonWhite from './white/person-white.svg';
import PlusCircleWhite from './white/plus-circle-white.svg';
import ShieldLockWhite from './white/shield-lock-white.svg';
import StarsWhite from './white/stars-white.svg';
import SwitchingWhite from './white/Switching-white.svg';
import UserWhite from './white/User-white.svg';
import XCircleWhite from './white/x-circle-white.svg';

import LogoBlack from './black/DATAflor_Logo.svg';
import AppBlack from './black/App-black.svg';
import BasketBlack from './black/basket-black.svg';
import BuildingBlack from './black/building-black.svg';
import DoorOpenBlack from './black/door-open black.svg';
import EmailAuthBlack from './black/Email-authent-black.svg';
import EmailBlack from './black/Email-black.svg';
import GearBlack from './black/gear-black.svg';
import GridBlack from './black/grid-black.svg';
import HouseBlack from './black/house-black.svg';
import ListBlack from './black/list-black.svg';
import OrganizationBlack from './black/Organization-black.svg';
import PersonPlusBlack from './black/person-plus-black.svg';
import PersonBlack from './black/person-black.svg';
import PlusCircleBlack from './black/plus-circle-black.svg';
import ShieldLockBlack from './black/shield-lock-black.svg';
import StarsBlack from './black/stars-black.svg';
import SwitchingBlack from './black/Switching-black.svg';
import UserBlack from './black/User-black.svg';
import XCircleBlack from './black/x-circle-black.svg';

export const themeIcons = {
  dark: {
    DATAflorLogo: LogoWhite,
    App: AppWhite,
    Basket: BasketWhite,
    Building: BuildingWhite,
    OpenDoor: DoorOpenWhite,
    EmailAuth: EmailAuthWhite,
    Email: EmailWhite,
    Gear: GearWhite,
    Grid: GridWhite,
    House: HouseWhite,
    List: ListWhite,
    Organization: OrganizationWhite,
    PersonPlus: PersonPlusWhite,
    Person: PersonWhite,
    PlusCircle: PlusCircleWhite,
    ShieldLock: ShieldLockWhite,
    Stars: StarsWhite,
    Switching: SwitchingWhite,
    User: UserWhite,
    XCircle: XCircleWhite,

  },
  light: {
    DATAflorLogo: LogoBlack,
    App: AppBlack,
    Basket: BasketBlack,
    Building: BuildingBlack,
    OpenDoor: DoorOpenBlack,
    EmailAuth: EmailAuthBlack,
    Email: EmailBlack,
    Gear: GearBlack,
    Grid: GridBlack,
    House: HouseBlack,
    List: ListBlack,
    Organization: OrganizationBlack,
    PersonPlus: PersonPlusBlack,
    Person: PersonBlack,
    PlusCircle: PlusCircleBlack,
    ShieldLock: ShieldLockBlack,
    Stars: StarsBlack,
    Switching: SwitchingBlack,
    User: UserBlack,
    XCircle: XCircleBlack,
  },
};

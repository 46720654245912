/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext, useState, useEffect,
} from 'react';
import User from './User';
import UserAvatar from '../Images/white/User-white.svg';
import GetUserProfile from '../Settings/UserQueries';

export const UserContext = createContext({
  setAvatar: (image: string) => {},
  refreshUser: () => {},
  userData: new User(),
  AvatarImage: undefined,
});

// eslint-disable-next-line react/function-component-definition, react/prop-types
const UserContextProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<User>(new User());
  const [AvatarImage, setAvatarImage] = useState(UserAvatar);

  const setAvatar = (image: any) => {
    setAvatarImage(image);
  };

  const refreshUser = () => {
    GetUserProfile().then((profile) => {
      setUserData(Object.assign(userData, profile));
    });
    console.log(userData.CognitoEmail);
  };
  useEffect(() => {
    refreshUser();
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <UserContext.Provider value={{
      userData, AvatarImage, setAvatar, refreshUser,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;

import '../StyleSheets/main.css';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { DefaultToastConfig } from '../ToastConfig';
import { useOrganizationHeaders } from '../Dashboard';
import GetAllOrganizationsWhereUserIsLastAdmin, { DeleteUser } from './AccountLoader';
import { OrganizationMetaData } from '../Organisation/OrganisationSettings';
import Urls from '../Urls';

export default function DeleteAccount() {
  const navigate = useNavigate();
  const [IsInputDirty, setIsInputDirty] = useState(false);
  const [organizationHeader] = useOrganizationHeaders();
  const [email, setEmail] = useState<string>();
  const [lastAdminOrgs, setLastAdminOrgs] = useState<OrganizationMetaData[]>();
  const lastAdminOrgsList = lastAdminOrgs?.map((org : OrganizationMetaData) => (
    <div>
      {org.Name}
    </div>
  ));

  async function Delete(e?: React.MouseEvent<HTMLButtonElement>) {
    if (e !== undefined)
      e.preventDefault();

    const deletionDate = new Date(Date.now());
    deletionDate.setDate(deletionDate.getDate() + 7);
    const user = await Auth.currentAuthenticatedUser();
    if (user.attributes.email !== email) {
      toast.info(t('Your email is incorrect. Please try again.'), DefaultToastConfig);
      return;
    }

    await DeleteUser();
    await Auth.updateUserAttributes(user, {
      'custom:Inactive': deletionDate.toJSON(),
    });
    await Auth.signOut();
    toast.info(t('Account has been scheduled for deletion.'), DefaultToastConfig);
    navigate(Urls.Login);
  }

  useEffect(() => {
    Effect();
    async function Effect() {
      const lastAdminOrgs = await GetAllOrganizationsWhereUserIsLastAdmin(organizationHeader.organisationMetaData!);
      setLastAdminOrgs(lastAdminOrgs);
    }
  }, [organizationHeader]);

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('Do you really want to delete your account?')}</h2>
        <a>{t('You lose access to all related DATAflor services and we permanently delete your personal data. You can cancel the deletion within 7 days.')}</a>
      </div>
      <div id="AccountDelete">
        <div hidden={lastAdminOrgs?.length === 0}>
          {t('You are the only adminstrator of')} <br />
          {lastAdminOrgsList} <br />
          {t('If you delete your user account, the above mentioned organizations will be deleted as well!')} <br />
          {t('To avoid deletion of these organizations, please add another user as an admin.')} <br /><br />
        </div>
        <form id="DeleteAccountForm">
          <label>
            {t('Please type in your email for confirmation.')}
          </label>
          <input id="singleInput" className="input" onChange={(e) => { setEmail(e.target.value); setIsInputDirty(true); }} />
          <div>
            <button id="rowButton" type="submit" onClick={Delete} disabled={!IsInputDirty}>{t('Delete')}</button>
            <button id="rowButton" onClick={() => navigate('/Account')}>{t('Cancel')}</button>
          </div>
        </form>
      </div>
    </div>
  );
}

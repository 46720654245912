import { useContext } from 'react';
import { Modal } from 'react-overlays';
import {ThemeContext} from './Images/ThemeContext';
import './StyleSheets/main.css';

function CustomizedModal(props: {children: any, show: boolean | undefined, onHide?: (() => void) }) {
  const { themeColor } = useContext(ThemeContext);
  return (
    <Modal
      className={`Modal ${themeColor}`}
      show={props.show}
      onHide={props.onHide}
      renderBackdrop={() => <div className="renderBackdrop" />}
      aria-labelledby="modal-label"
    >
      <div data-theme={themeColor}>
        {props.children}
      </div>
    </Modal>
  );
}

export default CustomizedModal;

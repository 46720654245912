import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import { OrganizationHeaders } from '../OrganizationHeaders';
import { GraphQLError } from '../Model/Enums';
import { IsMemberAdmin } from '../Settings/OrganisationMenuConfiguration';
import { LoadOrganizations } from './OrganisationLoader';

export default async function CheckAdminPermission(navigate : any, organizationMetaData: OrganizationHeaders) {
  const user = await Auth.currentAuthenticatedUser();
  if (!IsMemberAdmin(organizationMetaData.organisationMetaData!, user.attributes['custom:CurrentOrganization'])) {
    toast.info(t('Missing Permission.'), {
      position: 'bottom-right',
      autoClose: 5000,
      closeOnClick: true,
      progress: undefined,
      toastId: 'Missing Permission',
    });
    navigate('/');
  }
}

export async function HandleError(error : GraphQLError, setHeader: Dispatch<SetStateAction<OrganizationHeaders>>) {
  if (error === GraphQLError.AccessDenied) {
    toast.info(t('Missing Permission.'), {
      position: 'bottom-right',
      autoClose: 5000,
      closeOnClick: true,
      progress: undefined,
      toastId: 'Missing Permission',
    });
    await LoadOrganizations(setHeader);
  }
}

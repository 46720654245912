import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useSession } from './Loginpage';
import '../StyleSheets/main.css';
import '../StyleSheets/login.css';
import { SignIn } from './Login';

export default function VerificationWait() {
  const navigate = useNavigate();
  const [session, setSession] = useSession();
  const [, setCookie] = useCookies();
  const [cookie] = useCookies(['language']);

  if (session.registeredMail === undefined || session.registeredPasswort === undefined || session.signupConfirmationCode === undefined)
    navigate('/Verification');

  onVerify();
  async function onVerify() {
    try {
      await Auth.confirmSignUp(session.registeredMail ?? '', session.signupConfirmationCode!);
      await SignIn(session, setSession, navigate, cookie, setCookie);
    } catch (error: any) {
      toast.error(t('Your verification code is wrong. Please try again.'), {
        position: 'bottom-right',
        autoClose: 3000,
        toastId: 'verificationwaiterror',
      });
      navigate('/Verification');
    }
  }

  return (
    <div id="loginbox">
      <div id="login-body">
        <h3 id="single-text">{t('It takes only a few seconds to set your account up.')}</h3>
        <i className="Verificationloader" />
      </div>
    </div>
  );
}

import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import '../StyleSheets/main.css';
import { toast } from 'react-toastify';
import { DefaultToastConfig } from '../ToastConfig';

export default function MultiFactorDisable() {
  const navigate = useNavigate();
  const [multiFactorEmailEnabled, setMultiFactorEmailEnabled] = useState(false);
  const [multiFactorAppEnabled, setMultiFactorAppEnabled] = useState(false);

  Auth.currentAuthenticatedUser().then((x) => {
    Auth.getPreferredMFA(x).then((mfaPreferences) => {
      setMultiFactorAppEnabled(mfaPreferences === 'SOFTWARE_TOKEN_MFA');
      Auth.userAttributes(x).then((attributes) => {
        const attribute = attributes.find((a) => a.Name === 'custom:MultiFactorEmail');
        const mail = attribute?.getValue();
        setMultiFactorEmailEnabled(typeof mail !== 'undefined');
        if ((mfaPreferences !== 'SOFTWARE_TOKEN_MFA') && (typeof mail === 'undefined'))
          navigate('/Account');
      });
    });
  });

  async function DisableTwoFactor() {
    const user = await Auth.currentAuthenticatedUser();
    if (multiFactorAppEnabled)
      await Auth.setPreferredMFA(user, 'NOMFA');
    if (multiFactorEmailEnabled)
      await Auth.updateUserAttributes(user, { 'custom:MultiFactorEmail': '' });
    toast.info(t('Disabled two-factor authentication'), DefaultToastConfig);
    navigate('/MultiFactor');
  }

  return (
    <div id="PageBOdy">
      <div id="HeadLine">
        <h2>{t('Deactivate two factor authentication')}</h2>
        <a>{t('You have activated two factor authentication.')}</a>
        <a>{t('If you deactivate two factor authentication you will no longer be secured by a second step during login.')}</a>
      </div>
      <button
        className="button"
        onClick={DisableTwoFactor}
      >
        {t('Deactivate')}
      </button>
    </div>

  );
}

export default class Invitation {
  ID?: string = '';
  OrganizationID?: string = '';
  Email?: string = '';
  MyDataflorID?: string = '';
  MeasureWebID?: string = '';
  Invalidated?: string = '';

  constructor(data: Partial<Invitation>) {
    Object.assign(this, data);
  }
}

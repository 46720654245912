export default class Organization {
  Guid?: string;
  Name?: string = '';
  Trade?: string = '';
  ShippingAddress?: string = '';
  PostCode?: string = '';
  City?: string = '';
  Country?: string = '';
  State?: string = '';
  VATID?: string = '';

  Email?: string;
  PhoneNumber?: string;
  Homepage?: string;
  Xing?: string;
  LinkedIn?: string;

  IsActive?: boolean;
  DeletionDate? : Date;

  public constructor(init?:Partial<Organization>) {
    Object.assign(this, init);
  }
}

export interface CountryData {
  label?: string;
  value?: string;
}

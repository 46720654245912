import { t } from 'i18next';
import { Auth } from 'aws-amplify';
import {
  useParams, useNavigate,
} from 'react-router-dom';
import '../StyleSheets/main.css';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ThemeContext } from '../Images/ThemeContext';
import { LoadOrganizations } from './OrganisationLoader';
import { HasUserRole, OrganizationHeaders } from '../OrganizationHeaders';
import { MeasurewebRole } from '../Model/Enums';

export default function OrganisationInvite() {
  const [, setOrganizationState] = useState(new OrganizationHeaders());
  const { inviteID } = useParams();
  const navigate = useNavigate();
  const [IsPending, setIsPending] = useState(false);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    Processinvite();
    async function Processinvite() {
      await checkUser();
      setIsPending(true);
      await AcceptInvite();
      setIsPending(false);
    }
  }, []);

  async function AcceptInvite() {
    const user = await Auth.currentAuthenticatedUser();
    const json = JSON.stringify(
      {
        inviteID,
        user: user.username,
      },
    );
    const mailPost = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: json,
    };
    const response = await fetch(`${process.env.REACT_APP_BackendApi}/OrganizationInviteCode`, mailPost);
    const jsonresponse : any = await response.json();
    if (jsonresponse.Invalid) {
      toast.error(t('This invitation has been cancelled by an administrator. Check with your organizations administrator.'), {
        position: 'bottom-right',
        autoClose: false,
      });
      navigate('/');
    } else if (jsonresponse.message === 'Internal Server Error') {
      toast.error(t('An error occurred. Your invitation might have timed out. Try asking your administrator for another invite.'), {
        position: 'bottom-right',
        autoClose: false,
      });
      navigate('/');
    } else {
      toast.success(t('Invitation accepted'), {
        position: 'bottom-right',
        closeOnClick: true,
      });
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        'custom:CurrentOrganization': jsonresponse.OrganizationID.toString(),
      });
      await Redirect();
    }
  }

  async function Redirect() {
    const headerData = await LoadOrganizations(setOrganizationState);
    if (await HasUserRole(MeasurewebRole.User, headerData)) {
      try {
        await Auth.signOut();
        navigate('/Login/MeasureWeb');
      } catch (error) {
        console.log('error signing out: ', error);
      }
    } else {
      navigate('/');
    }
  }

  async function checkUser() {
    try {
      await Auth.currentAuthenticatedUser();
    } catch {
      return navigate('Login', { state: { RedirectURL: `/OrganizationInvite/${inviteID}` } });
    }
  }

  return (
    <div id="InviteOrgDiv">
      <img id="LogoHeadline" src={theme.DATAflorLogo} alt="Logo" />
      <h3>{t('You are added to the new organization right know')}</h3>
      <h4>{t('It takes only a few seconds to rewire some connections')}</h4>
      {IsPending && <i className="Verificationloader" />}
    </div>
  );
}

import { Auth } from 'aws-amplify';
import { MeasurewebRole, ServiceRole } from './Model/Enums';
import { OrganizationMetaData } from './Organisation/OrganisationSettings';

export class OrganizationHeaders {
  loadingFinished = false;

  currentOrganisationName?: string;
  organisationMetaData?: OrganizationMetaData[];

  constructor(
    currentOrganisationName?: string,
    organisationMetaData?: OrganizationMetaData[],
    loadingfinished?: boolean,
  ) {
    this.currentOrganisationName = currentOrganisationName;
    this.organisationMetaData = organisationMetaData;
    this.loadingFinished = loadingfinished ?? false;
  }
}

export async function GetActiveOrganization(header : OrganizationHeaders) {
  if (!header.loadingFinished)
    return null;
  const user = await Auth.currentAuthenticatedUser();
  return header.organisationMetaData?.find((x) => x.ID.toString() === user.attributes['custom:CurrentOrganization']);
}

export async function HasUserRole(role : MeasurewebRole | ServiceRole, header: OrganizationHeaders) {
  if (!header.loadingFinished)
    return false;
  const activeOrg = await GetActiveOrganization(header);
  const existingRole = activeOrg?.MemberRoles?.find((x) => x === role);
  return (existingRole !== undefined);
}

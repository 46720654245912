import React, { useEffect, useState } from 'react';
import {
  useNavigate, Link, useParams, useLocation,
} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { t } from 'i18next';
import { useCookies } from 'react-cookie';
import { setLanguageAndCookie, Language } from '../Settings/Language';
import SetLoginRedirect, { ConfigureService } from './RedirectHelper';
import logoPath from '../Images/google.svg';
import '../StyleSheets/login.css';
import { Session, useSession } from './Loginpage';
import '../StyleSheets/main.css';

export default function Login() {
  const navigate = useNavigate();
  const location : any = useLocation();
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginEnabled, setIsLoginEnabled] = useState(true);
  const [session, setSession] = useSession();
  const { Service } = useParams();
  const [, setCookie] = useCookies();
  const [cookie] = useCookies(['language']);

  useEffect(() => {
    const newSession = new Session(session);
    newSession.localRedirect = location?.state?.RedirectURL;
    newSession.service = Service;
    setSession(newSession);
    ConfigureService(Service ?? 'SelfService');
  }, [location]);

  const onLogin = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsLoginEnabled(false);
    try {
      session.mail = mail;
      session.password = password;
      setSession(session);
      await SignIn(session, setSession, navigate, cookie, setCookie);
    } catch (error) {
      console.log(`error signing in${error}`, error);
      toast.error(t('Your E-Mail or your password is incorrect. Please try again.'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      setIsLoginEnabled(true);
    }
    setIsLoginEnabled(true);
  };

  const onRegister = async () => {
    navigate('/Registration');
  };

  return (
    <div id="loginbox">
      <div id="login-body">
        <form>
          <input id="loginbox-input" type="text" autoFocus onChange={(x) => setMail(x.target.value)} placeholder={t('E-Mail')} />
          <input id="loginbox-input" type="password" onChange={(x) => setPassword(x.target.value)} placeholder={t('Password')} />
          <div id="forgot-password-link">
            <Link id="Link" to="/RetrievePassword">{t('Forgot your password?')}</Link>
          </div>
          <button id="loginbox-button" type="submit" onClick={onLogin} disabled={!isLoginEnabled}>
            {!isLoginEnabled && <i className="loader" />}
            {t('Login')}
          </button>
        </form>
        <div id="social-icons" hidden>
          <button
            id="social-button"
            onClick={() => federatedSignIn(session)}
          >
            <img
              src={logoPath}
              alt=""
            />
            {t('Google')}
          </button>
        </div>
        <div id="separator">
          <div id="line" />
          <h2>{t('or sign up here')}</h2>
          <div id="line" />
        </div>
        <button id="loginbox-button" type="submit" onClick={onRegister} disabled={!isLoginEnabled}>
          {t('Register')}
        </button>
      </div>
    </div>
  );
}
export async function SignIn(
  currentSession: Session,
  setSession: React.Dispatch<React.SetStateAction<Session>>,
  navigate : any,
  cookie: { language?: any; },
  setCookie : any,
) {
  Auth.configure({
    authenticationFlowType: 'CUSTOM_AUTH',
  });
  const response = await Auth.signIn(currentSession.mail!, currentSession.password!);
  if (response.challengeName === 'CUSTOM_CHALLENGE'
  || response.challengeName === 'SOFTWARE_TOKEN_MFA') {
    const newSession = new Session(currentSession);
    newSession.loginSession = response;
    setSession(newSession);
    navigate('/SecondFactorChallenge');
  } else {
    await SetLoginRedirect(currentSession, setSession, null);
    currentSession.Redirect(navigate);
    setLanguageAndCookie(response.attributes.locale ?? cookie.language as Language, setCookie);
  }
}

export async function federatedSignIn(session: Session) {
  const Service = session.service!;
  ConfigureService(Service!, Service !== 'SelfService');
  if (Service !== 'SelfService') {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: Service });
  } else
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google, customState: session.localRedirect });
}

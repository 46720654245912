/* eslint-disable max-len */
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from 'react';
import {
  Outlet, useLocation, useNavigate, useOutletContext,
} from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import './StyleSheets/main.css';
import Navbar, { SlideMenu } from './Navbar';
import { OrganizationMetaData } from './Organisation/OrganisationSettings';
import { FixedToast } from './ToastConfig';
import { LoadOrganizations } from './Organisation/OrganisationLoader';
import OrganizationDefaultImage from './Images/white/Organization-white.svg';
import { getAvatar, GetOrganizationLogo } from './Settings/AvatarRequests';
import { ThemeContext } from './Images/ThemeContext';
import { UserContext } from './Model/UserContext';

export class OrganizationHeaders {
  loadingFinished = false;

  currentOrganisationName?: string;
  organisationMetaData?: OrganizationMetaData[];

  constructor(
    currentOrganisationName?: string,
    organisationMetaData?: OrganizationMetaData[],
    loadingfinished?: boolean,
  ) {
    this.currentOrganisationName = currentOrganisationName;
    this.organisationMetaData = organisationMetaData;
    this.loadingFinished = loadingfinished ?? false;
  }
}

export default function Dashboard() {
  const [myDataflorCache, setMyDataflorCache] = useState<OrganizationHeaders>(new OrganizationHeaders());
  const [slideMenuWidth, setSlideMenuWidth] = useState('large-slideMenu');
  const [currentOrganizationImage, setcurrentOrganizationImage] = useState(OrganizationDefaultImage);
  const navigate = useNavigate();
  const location = useLocation();
  const { themeColor, theme } = useContext(ThemeContext);
  const { userData, setAvatar, refreshUser } = useContext(UserContext);

  Hub.listen('auth', ({ payload: { event, data } }) => {
    console.log(event);
    if (event === 'customOAuthState')
      navigate(data);
  });

  async function checkUser() {
    try {
      await Auth.currentAuthenticatedUser();
    } catch {
      return navigate('/Login');
    }
  }

  ValidateOrganizationState();
  async function ValidateOrganizationState() {
    if (!myDataflorCache.loadingFinished)
      return;
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    if (user.attributes['custom:Inactive']) {
      if (location.pathname !== '/ActivateAccount')
        navigate('/ActivateAccount');
      return;
    }
    if (!myDataflorCache.organisationMetaData?.some((x) => x.IsActive) && location.pathname !== '/CreateOrganization') {
      toast.info(t('Could not find an organization that you are part of. Please create a new one.'), {
        ...FixedToast,
        toastId: 'No Active org',
      });
      navigate('CreateOrganization');
    }
    const activeOrganizationID = user.attributes['custom:CurrentOrganization'];
    let activeOrganization = myDataflorCache.organisationMetaData?.find((x) => x.ID.toString() === activeOrganizationID);
    if (activeOrganization == null || !activeOrganization.IsActive) {
      const warning = activeOrganization
      // eslint-disable-next-line max-len
        ? t('The organization {{name}} is inactive due to a deletion request of your admin, and your current organization has changed. For further information contact your admin or the DATAflor support', { name: activeOrganization.Name })
        : t('You have been removed from your organization and your active organization has changed. For more information contact your admin.');
      // eslint-disable-next-line prefer-destructuring
      activeOrganization = myDataflorCache.organisationMetaData?.find((x : OrganizationMetaData) => x.ID !== activeOrganization?.ID && x.IsActive);
      if (activeOrganization) {
        await Auth.updateUserAttributes(user, {
          'custom:CurrentOrganization': activeOrganization?.ID.toString(),
        });
        toast.info(warning, { ...FixedToast, toastId: 'KickedFromOrg' });
        navigate('/OrganizationList');

        // Rerender with updated organization
        setMyDataflorCache((x) => ({ ...x, currentOrganisationName: activeOrganization?.Name }));
      }
    }
  }

  useEffect(() => {
    async function Effect() {
      checkUser();
      refreshUser();
      const user = await Auth.currentAuthenticatedUser();
      const organization = await LoadOrganizations(setMyDataflorCache);
      // eslint-disable-next-line prefer-template
      setAvatar(await getAvatar(theme, (userData.Surname ?? '') + ' ' + (userData.LastName ?? '')));
      setcurrentOrganizationImage(await GetOrganizationLogo(user.attributes['custom:CurrentOrganization'], theme, organization.currentOrganisationName!));
    }
    Effect();
  }, [theme]);

  return (
    <div data-theme={themeColor}>
      <div id="navbar">
        <Navbar Data={myDataflorCache} setSlideMenuWidth={setSlideMenuWidth} currentOrganizationImage={currentOrganizationImage} />
      </div>
      <div id="content">
        <div id={slideMenuWidth}>
          <div className="SlideMenu" id={slideMenuWidth}>
            <SlideMenu SlideMenuWidth={slideMenuWidth} />
          </div>
        </div>
        <div id="Outlet">
          <Outlet context={[myDataflorCache, setMyDataflorCache, setcurrentOrganizationImage]} />
        </div>
      </div>
    </div>
  );
}

export function useOrganizationHeaders() {
  return useOutletContext<[OrganizationHeaders, Dispatch<SetStateAction<OrganizationHeaders>>, Dispatch<SetStateAction<any>>]>();
}

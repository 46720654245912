import '../StyleSheets/main.css';
import { Auth } from 'aws-amplify';
import { OrganizationMetaData } from '../Organisation/OrganisationSettings';
import { ServiceRole } from '../Model/Enums';
import { LoadMembers } from '../Organisation/OrganisationLoader';

export default async function GetAllOrganizationsWhereUserIsLastAdmin(metaData: OrganizationMetaData[]) : Promise<OrganizationMetaData[]> {
  const user = await Auth.currentAuthenticatedUser();
  const administratedOrganizations = metaData.filter((x) => x.IsActive && x.MemberRoles?.find((y) => y === ServiceRole.Admin));
  const resultArray: OrganizationMetaData[] = [];
  for (const org of administratedOrganizations) {
    if (await IsLastAdmin(org, user.username)) {
      resultArray.push(org);
    }
  }
  return resultArray;
}

async function IsLastAdmin(org: OrganizationMetaData, username: string) : Promise<boolean> {
  const members = await LoadMembers(org.ID.toString());
  const otherAdmin = members.find((x) => x.User?.CognitoUsername !== username && x.MemberRoles?.find((y) => y.Role?.ID === ServiceRole.Admin));
  return !otherAdmin;
}

export async function DeleteUser() {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
          mutation deleteUser($ID: ID!, $accessKey: ID!) { 
            deleteUser(cognitoUsername: $ID, accessKey: $accessKey)
          }
        `,
      variables: {
        ID: user.username,
        accessKey: user.signInUserSession.accessToken.jwtToken,
      },
    }),
  });
  const json = await response.json();
  return json.data.deleteUser;
}

export async function ReactivateUser() {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
        mutation reactivateUser($ID: ID!, $accessKey: ID!) { 
            reactivateUser(cognitoUsername: $ID, accessKey: $accessKey)
          }
        `,
      variables: {
        ID: user.username,
        accessKey: user.signInUserSession.accessToken.jwtToken,
      },
    }),
  });
  const json = await response.json();
  return json.data.reactivateUser;
}

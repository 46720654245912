import MemberRole from './MemberRole';
import User from './User';

export default class OrganizationMember {
  Guid?: string | number = '';
  Organization?: string = '';
  User?: User;
  MemberRoles?: MemberRole[];

  constructor(data: Partial<OrganizationMember>) {
    Object.assign(this, data);
  }
}

import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import '../StyleSheets/main.css';
import '../StyleSheets/login.css';
import { toast } from 'react-toastify';
import { useSession } from './Loginpage';
import validateEmail from '../validateEmail';

export default function RetrievePassword() {
  const [mail, setMail] = useState('');
  const navigate = useNavigate();
  const [session, setSession] = useSession();

  const sendMail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!validateEmail(mail)) {
      toast.error(t('Please enter a valid email address.'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      return;
    }

    try {
      Auth.forgotPassword(mail)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
      return;
    }
    session.mail = mail;
    setSession(session);
    navigate('/RetrievePasswordConfirmation');
  };

  return (
    <div id="loginbox">
      <div id="login-body">
        <form>
          <div id="single-text">
            {t('Enter the E-Mail that you use to log into your DATAflor account.')}
          </div>
          <input id="loginbox-input" autoFocus type="text" onChange={(x) => setMail(x.target.value)} placeholder={t('E-Mail address')} />
          <button id="loginbox-button" type="submit" onClick={sendMail}>
            {t('Continue')}
          </button>
          <button id="loginbox-button" type="submit" className="basicButton" onClick={() => navigate('/Login')}>
            {t('back')}
          </button>
        </form>
      </div>
    </div>
  );
}

const ServiceConfiguration = {
  SelfService: {
    ClientID: process.env.REACT_APP_ClientID,
    Redirect: `https://${window.location.hostname}`,
  },
  Local: {
    ClientID: process.env.REACT_APP_ClientID,
    Redirect: 'https://localhost:3000/',
  },
  MeasureWebLocal: {
    ClientID: process.env.REACT_APP_MeasureWebClientID,
    Redirect: 'https://localhost:7273/',
  },
  MeasureWebBeta: {
    ClientID: process.env.REACT_APP_MeasureWebClientID,
    Redirect: 'https://dataflorpotree-beta.azurewebsites.net/',
  },
  MeasureWeb: {
    ClientID: process.env.REACT_APP_MeasureWebClientID,
    Redirect: process.env.REACT_APP_MeasureWebRedirect!,
  },
  CAD: {
    ClientID: process.env.REACT_APP_CADClientID,
    Redirect: 'https://localhost/',
  },
};
export default ServiceConfiguration;

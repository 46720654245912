/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import i18next, { t } from 'i18next';
import * as localCountry from 'i18n-iso-countries';
import * as CountryState from 'country-state-city';
import NavBarUk from '../Images/flags/UK.png';
import NavBarGer from '../Images/flags/GER.png';
import NavBarNl from '../Images/flags/NL.png';
import '../StyleSheets/main.css';
import i18n from '../../i18n';

export type Language = 'en' | 'de' | 'tlh' | 'nl';

export function setLanguageAndCookie(language: Language, setCookie: any) {
  if (i18n.language === language || language === undefined)
    return;
  i18n.changeLanguage(language);

  setCookie('language', language, { secure: true, maxAge: 31536000 });
}

const getFlag = () => {
  if (i18n.language === 'de') {
    return NavBarGer;
  }
  if (i18n.language === 'nl') {
    return NavBarNl;
  }
  return NavBarUk;
};

export function LanguageSelector(props: {LanguageChangedCallback: (language: Language) => void;}) {
  const [toggleDropdown, setToggleDropdown] = useState(false);

  const [flag, setFlag] = useState(getFlag());

  const handleToggle = (event: any) => {
    event.preventDefault();
    // We stop event propagation, so the opening click doesn't also immediately close the dropdown.
    event.stopPropagation();
    setToggleDropdown(!toggleDropdown);
  };

  const closeToggle = () => {
    setToggleDropdown(false);
  };
  return (
    <div id="LanguageSelectorContainer">
      <button id="LanguageSelector" onClick={handleToggle}>
        <img
          src={flag}
          alt=""
        />
      </button>
      {toggleDropdown
      && <LanguageDropDown setFlag={setFlag} closeToggle={closeToggle} setToggleDropdown={setToggleDropdown} LanguageChangedCallback={props.LanguageChangedCallback} />}
    </div>
  );
}

function LanguageDropDown(props: { closeToggle: any; setFlag: any; setToggleDropdown: any; LanguageChangedCallback: (language: Language) => void;}) {
  const ref = useDetectClickOutside({ onTriggered: props.closeToggle });
  return (
    <div id="LanguageDropdown" ref={ref}>
      <button
        className="LanguageButton"
        onClick={() => { props.setFlag(NavBarGer); props.setToggleDropdown(false); props.LanguageChangedCallback('de'); }}
      >
        <img
          src={NavBarGer}
          alt=""
          id="countryFlag"
        />
        <span>{t('de')}</span>
      </button>
      <button
        className="LanguageButton"
        onClick={() => { props.setFlag(NavBarUk); props.setToggleDropdown(false); props.LanguageChangedCallback('en'); }}
      >
        <img
          src={NavBarUk}
          alt=""
          id="countryFlag"
        />
        <span>{t('en')}</span>
      </button>
      <button
        className="LanguageButton"
        onClick={() => { props.setFlag(NavBarNl); props.setToggleDropdown(false); props.LanguageChangedCallback('nl'); }}
      >
        <img
          src={NavBarNl}
          alt=""
          id="countryFlag"
        />
        <span>{t('nl')}</span>
      </button>
    </div>
  );
}

export async function RegisterCountryLocale() {
  const language = await import(`i18n-iso-countries/langs/${i18n.language}.json`);
  localCountry.registerLocale(language);
}

export async function LoadCountriesState(Country: string | undefined, setCountries: any, setStates: any) {
  function customCompare(a : any, b : any): 1 | -1 | 0 {
    const A = a.label;
    const B = b.label;
    if (A < B)
      return -1;
    if (A > B)
      return 1;
    return 0;
  }
  const { language } = i18next;
  await RegisterCountryLocale();
  setCountries(CountryState.Country.getAllCountries().map((x) => ({ label: localCountry.getName(x.isoCode, language), value: x.isoCode })).sort(customCompare));
  if (Country) {
    const allStates = CountryState.State.getStatesOfCountry(Country);
    const translatedStates = allStates.map((x) => ({ label: t(x.name), value: x.isoCode })).sort(customCompare);
    setStates(translatedStates);
  }
}

/* eslint-disable jsx-quotes */
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import QRCode from 'qrcode.react';
import { useNavigate } from 'react-router-dom';
import '../StyleSheets/main.css';
import { DefaultToastConfig } from '../ToastConfig';
import { ThemeContext } from '../Images/ThemeContext';

export default function MultiFactorApp() {
  const navigate = useNavigate();
  const [qrCode, setQrCode] = useState('');
  const [secretWithLineBreak, setsecretWithLineBreak] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  let challengeAnswer = '';
  useEffect(() => {
    async function LoadQRCode() {
      const user = await Auth.currentAuthenticatedUser();
      Auth.setupTOTP(user)
        .then((code) => {
          setQrCode(`otpauth://totp/DATAflorCloud:${user.attributes.email}?secret=${code}`);
          const addedLinBreak = `${code.slice(0, (code.length / 2))}\r\n${code.slice((code.length / 2), code.length)}`;
          setsecretWithLineBreak(addedLinBreak);
          console.log(code);
        });
    }
    LoadQRCode();
  }, []);
  async function onActivate(e: any) {
    e.preventDefault();
    setIsLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    Auth.verifyTotpToken(user, challengeAnswer)
      .then(() => {
        Auth.setPreferredMFA(user, 'TOTP').then(() => {
          toast.success(t('Authentication per app is activated'), DefaultToastConfig);
          setIsLoading(false);
          navigate('/Account');
        });
      })
      .catch((e) => {
        toast.info(t('Your verification code is wrong. Please try again.'), DefaultToastConfig);
        setIsLoading(false);
        console.log(e);
      });
  }

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('Two factor authentication via app')}</h2>
        <a>{t('For two factor authentication via app please follow these steps.')}</a>
      </div>
      <div id="LandingPage">
        <div id="mfaAppTile">
          <form>
            <div id="BlockHeadLine">
              <h3>{t('1. Install app')}</h3>
            </div>
            <img
              id="mfaAppTileImg"
              src={theme.App}
              alt=""
            />
            <a>{t('Please download an authentication app to your smartphone or tablet.')}</a>
            <a id="mfaAppLink" href='https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en&gl=US'>
              {t('Microsoft Authenticator')}
            </a>
            <a id="mfaAppLink" href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'>
              {t('Google Authenticator')}
            </a>
          </form>
        </div>
        <div id="mfaAppTile">
          <form>
            <div id="BlockHeadLine">
              <h3>{t('2. Scan QR Code')}</h3>
            </div>
            <div id='qrCode'>
              <QRCode value={qrCode} />
            </div>
            <div id="leftAlignedA">
              <a>{t('Scan this QR Code using your authentication app.')}</a>
              <a>1. {t('Within you Authenticator app, add a new account.')}</a>
              <a>2. {t('Based on your app you may need to specify that you want to have time based codes.')}</a>
              <a>3. {t('Finally, scan the following QRCode.')}</a>
            </div>
            <div id="alternateCode">
              <a>{t('Alternatively you can enter the following code into your app:')}</a>
              <textarea className="inputArea" defaultValue={secretWithLineBreak} disabled />
            </div>
          </form>
        </div>
        <div id="mfaAppTile">
          <form>
            <div id="BlockHeadLine">
              <h3>{t('3. Enter confirmation code.')}</h3>
            </div>
            <div>
              <img
                id="mfaAppTileImgWide"
                src={theme.Stars}
                alt=""
              />
            </div>
            <a id="ArticleWithMargin">{t('After scanning the QR Code, you will receive a confirmation code. Enter this code below.')}</a>
            <input className="input" onChange={(x) => (challengeAnswer = x.target.value)} /><br />
            <button onClick={(e) => onActivate(e)}>
              {isLoading && <i className="loader" />}
              {t('Enable')}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

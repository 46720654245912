// eslint-disable-next-line max-classes-per-file
import OrganizationMember from './OrganizationMember';

export default class MemberRole {
  ID?: string = '';
  Role?: Role;
  OrganizationMember?: OrganizationMember;

  public constructor(init?:Partial<MemberRole>) {
    Object.assign(this, init);
  }
}

export class Role {
  ID?: number = 0;
  Name?: string = '';
}

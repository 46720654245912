import {
  ChangeEvent, useEffect, useState,
} from 'react';
import '../StyleSheets/main.css';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import user from '../Images/white/User-white.svg';
import { DefaultToastConfig } from '../ToastConfig';

function Avatar(props: { UploadAvatar: (image: File, callback: () => void) => void; getAvatar: () => any; }) {
  const [avatar, setAvatar] = useState<string>('');

  async function UploadFile(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files![0].size > 10000000) {
      toast.info(t('Image file too big, please choose a smaller file'), DefaultToastConfig);
      return;
    }
    props.UploadAvatar(event.target.files![0], Load);
  }

  async function Load() {
    setAvatar(await props.getAvatar());
  }

  async function Remove(e: any) {
    e.preventDefault();
    props.UploadAvatar(new File([user], 'file', { type: 'image/svg' }), Load);
  }

  useEffect(() => {
    Load();
  }, [props.getAvatar]);

  return (
    <div className="centeredDiv">
      <input
        type="file"
        id="file"
        hidden
        accept="image/png, image/gif, image/jpeg"
        onChange={UploadFile}
      />
      <label htmlFor="file">
        <img
          id="AvatarImage"
          src={avatar}
          alt=""
        />
      </label>
      <button className="button" hidden={!avatar.startsWith('blob')} onClick={Remove}>
        {t('Remove')}
      </button>
    </div>
  );
}

export default Avatar;

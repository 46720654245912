import { createContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { themeIcons } from './Theme';

export type ThemeColor = 'dark' | 'light';

export const ThemeContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleTheme: () => {},
  theme: themeIcons.dark,
  themeColor: 'dark' as ThemeColor,
});

// eslint-disable-next-line react/function-component-definition, react/prop-types
const ThemeContextProvider: React.FC = ({ children }) => {
  const [themeCookie] = useCookies(['theme']);
  const [theme, setTheme] = useState(themeCookie.theme === 'light' ? themeIcons.light : themeIcons.dark);
  const [themeColor, setThemeColor] = useState<ThemeColor>(themeCookie.theme as ThemeColor ?? 'dark');

  const toggleTheme = () => {
    if (theme === themeIcons.dark) {
      setTheme(themeIcons.light);
      setThemeColor('light');
    } else {
      setTheme(themeIcons.dark);
      setThemeColor('dark');
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ThemeContext.Provider value={{ theme, toggleTheme, themeColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './language-dist/en/translation.json';
import translationDE from './language-dist/de/translation.json';
import translationNL from './language-dist/nl/translation.json';
import klingon from './language/tlh/translation.json';

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY, // YOU should not expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

const resources = {
  en: { translation: translationEN },
  de: { translation: translationDE },
  tlh: { translation: klingon },
  nl: { translation: translationNL },
};

i18n

  .use(initReactI18next)

  .init({
    resources,
    react: {
      useSuspense: false,
    },
    debug: true,
    fallbackLng: 'en',
    lng: navigator.language.trim().slice(0, 2),
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    saveMissing: !isProduction,
  });

export default i18n;

import './StyleSheets/main.css';
import React, { Dispatch, SetStateAction } from 'react';
import PasswordCheklist from 'react-password-checklist';
import { t } from 'i18next';

function PasswordChecklist(props: {password: string; passwordAgain: string, onIsValidChanged: Dispatch<SetStateAction<boolean>> }) {
  return (
    <div id="passwordchecker">
      <PasswordCheklist
        className="PasswordChecklist"
        rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
        minLength={8}
        value={props.password}
        valueAgain={props.passwordAgain}
        onChange={(x) => props.onIsValidChanged(x)}
        messages={{
          minLength: t('Password has more than 8 characters'),
          specialChar: t('Password has special characters'),
          number: t('Password has a number'),
          capital: t('Password has a capital letter'),
          lowercase: t('Password has a lowercase letter'),
          match: t('Passwords are matching'),
        }}
      />
    </div>
  );
}

export default PasswordChecklist;

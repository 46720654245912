import { t } from 'i18next';
import '../StyleSheets/main.css';
import { MeasurewebRole, ServiceRole } from '../Model/Enums';

export default function MyDATAflorRoles() {
  return [
    {
      value: ServiceRole.Admin,
      label: RoleLabel(t('admin')),
    },

    {
      value: ServiceRole.None,
      label: RoleLabel(t('none')),
    },
  ];
}

export function MeasureWebRoles() {
  return [
    {
      value: MeasurewebRole.User,
      label: RoleLabel(t('user')),
    },

    {
      value: MeasurewebRole.None,
      label: RoleLabel(t('none')),
    },
  ];
}

function RoleLabel(label: string) {
  return (
    <div>
      {label}
    </div>
  );
}

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import i18n from '../../i18n';
import Logo from '../Images/white/DATAflor_Logo.svg';

export default function EmailCodeValidationOverlay(props: { closeOverlay: (success: boolean) => void; multifactor: boolean}) {
  const [verificationCode, setverificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);

  const navigate = useNavigate();

  async function sendVerification() {
    setIsVerifying(true);
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.accessToken.jwtToken;
    const json = JSON.stringify(
      {
        jwt: token,
        locale: i18n.language,
        verificationCode,
      },
    );
    const mailPost = {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: json,
    };
    const endpoint = props.multifactor ? '/MultiFactorEmailCode' : '/VerificationEmailCode';
    await fetch(`${process.env.REACT_APP_BackendApi}${endpoint}`, mailPost)
      .then((response) => {
        if (response.ok) {
          props.closeOverlay(true);
          if (props.multifactor)
            toast.success(t('Authentication with E-Mail is activated'), {
              position: 'bottom-right',
              autoClose: 3000,
            });
          navigate('/Account');
        } else {
          toast.error(t('Your code is incorrect. Please check your code and try again.'), {
            position: 'bottom-right',
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setIsVerifying(false);
        console.log(`Error Verifying Code:${err}`);
      });
    setIsVerifying(false);
  }

  return (
    <div id="PageBody">
      <div id="ModalBody">
        <img
          src={Logo}
          alt="Logo"
          id="Modalimg"
        />
        <div id="ModalHeadLine">
          <h3>{t('verification')}</h3>
          <a>{t('emailCode')}</a>
        </div>
        <input
          onChange={(x) => setverificationCode(x.target.value)}
          className="input"
        />
        <div id="inline">
          <button
            onClick={() => props.closeOverlay(false)}
          >
            {t('back')}
          </button>
          <button
            onClick={sendVerification}
          >
            {isVerifying && <i className="loader" />}
            {t('verification')}
          </button>
        </div>
      </div>
    </div>
  );
}

import { Auth } from 'aws-amplify';
import Deserialize from '../Organisation/Deserialize';
import User from '../Model/User';
import { GraphQLError } from '../Model/Enums';

async function GetUserProfile() : Promise<User | GraphQLError> {
  const user = await Auth.currentAuthenticatedUser();
  const response = await fetch(`${process.env.REACT_APP_BackendApi}/GraphQL`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query:
    `
      query user($CognitoUsername: ID!, $accessKey: ID!) { 
        user(CognitoUsername: $CognitoUsername, accessKey: $accessKey) 
        { 
          LastName,
          Surname,
          ContactEmail,
          PhoneNumber,
          Xing,
          LinkedIn, 
          Address,
          PostCode,
          City,
          Country,
          State
        } 
      }
    `,
      variables: {
        CognitoUsername: user.username,
        accessKey: user.signInUserSession.accessToken.jwtToken,
      },
    }),
  });
  const json = await response.json();
  return Deserialize(User, json, 'user');
}

export default GetUserProfile;

/* eslint-disable max-len */
import {
  Dispatch, SetStateAction, useContext, useState,
} from 'react';
import {
  NavigateFunction,
  Outlet, useOutletContext,
} from 'react-router-dom';
import { useCookies } from 'react-cookie';

import ThemeSwitch from '../ThemeSwitch';
import { Language, LanguageSelector, setLanguageAndCookie } from '../Settings/Language';
import Footer from '../Footer';
import '../StyleSheets/login.css';
import '../StyleSheets/main.css';
import { ThemeContext } from '../Images/ThemeContext';

export class Session {
  // The cognito session, used during login.
  loginSession?: any;
  service?: string;

  // Credentials for automated log in after sign up.
  registeredMail?: string;
  registeredPasswort?: string;

  // Credentials for redirected login.
  mail?: string;
  password?: string;

  // For redirecting within the mainpage after successful login/registration for e.g. Organizationinvites
  localRedirect?: string;
  // The url of a different service to which the user will be redirected after login.
  RedirectUrl?: string;

  signupConfirmationCode?: string;

  Redirect(navigate: NavigateFunction) {
    if (this.RedirectUrl)
      window.location.href = this.RedirectUrl;
    if (this.localRedirect)
      navigate(this.localRedirect);
    else
      navigate('/');
  }
  public constructor(init?:Partial<Session>) {
    Object.assign(this, init);
  }
}
export default function Loginpage() {
  const [session, setSession] = useState<Session>(new Session());
  const [, setCookie] = useCookies(['language']);
  const { theme, themeColor } = useContext(ThemeContext);

  return (
    <div id="loginpage-content" data-theme={themeColor}>
      <div id="header">
        <LanguageSelector LanguageChangedCallback={(language: Language) => setLanguageAndCookie(language, setCookie)} />
      </div>
      <img src={theme.DATAflorLogo} alt="Logo" />
      <div id="page-body">
        <Outlet context={[session, setSession]} />
      </div>
      <div id="footer-div">
        <Footer />
      </div>
    </div>
  );
}

export function useSession() {
  return useOutletContext<[Session, Dispatch<SetStateAction<Session>>]>();
}

import { t } from 'i18next';
import {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useNavigate } from 'react-router';
import { Auth } from 'aws-amplify';
import '../StyleSheets/main.css';
import { ThemeContext } from '../Images/ThemeContext';
import { UserContext } from '../Model/UserContext';

export function UserMenu(props: {toggleState: [boolean, Dispatch<SetStateAction<boolean>>], closeOrganizationMenu: () => void}) {
  const [toggleDropdown, setToggleDropdown] = props.toggleState;
  const { AvatarImage } = useContext(UserContext);

  function handleToggle(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    // We stop event propagation, so the opening click doesn't also immediately close the dropdown.
    event.stopPropagation();
    props.closeOrganizationMenu();
    setToggleDropdown(!toggleDropdown);
  }

  function closeToggle() {
    setToggleDropdown(false);
  }

  return (
    <div id="MenuDiv">
      <button id="UserMenuButton" onClick={handleToggle}>
        <img
          id="UserMenuImg"
          src={AvatarImage}
          alt=""
        />
      </button>
      {toggleDropdown
      && <UserDropDown closeToggle={closeToggle} setToggleDropdown={setToggleDropdown} />}
    </div>
  );
}

// eslint-disable-next-line react/no-unused-prop-types
function UserDropDown(props: { closeToggle: any; setToggleDropdown: any;}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const ref = useDetectClickOutside({ onTriggered: props.closeToggle });
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const { userData, AvatarImage } = useContext(UserContext);

  useEffect(() => { Load(); }, []);

  async function Load() {
    const user = await Auth.currentAuthenticatedUser();
    setEmail(user.attributes.email ?? '');
    // eslint-disable-next-line prefer-template
    setName((userData.Surname ?? '') + ' ' + (userData.LastName ?? ''));
  }

  async function signOut() {
    try {
      await Auth.signOut();
      navigate('/Login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <div id="CustomDropdown" ref={ref}>
      <button id="profile">
        <img
          src={AvatarImage}
          alt=""
        />
        {name} <br />
        {email}
      </button>
      <div className="Seperator" />
      <button
        id="CustomDropdownButton"
        onClick={() => { props.setToggleDropdown(false); navigate('profile'); }}
      >
        <img
          src={theme.Person}
          alt=""
        />
        <span>{t('settings')}</span>
      </button>
      <button
        id="CustomDropdownButton"
        onClick={() => { props.setToggleDropdown(false); signOut(); }}
      >
        <img
          src={theme.OpenDoor}
          alt=""
        />
        <span>{t('logout')}</span>
      </button>
    </div>
  );
}

export function UserMenuIcon() {
  return [
    {
      value: 'User',
      label: UserButton(),
    },
  ];
}

function UserButton() {
  const { theme } = useContext(ThemeContext);
  return (
    <div>
      <img
        className="headerMenuImg"
        src={theme.User}
        alt=""
      />
    </div>
  );
}

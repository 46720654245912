/* eslint-disable react/jsx-no-constructed-context-values */
import './App.css';
import './ui-components/StyleSheets/login.css';
import { Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useContext, useEffect } from 'react';
import Login from './ui-components/Login/Login';
import Loginpage from './ui-components/Login/Loginpage';
import Registration from './ui-components/Login/Registration';
import Verification from './ui-components/Login/Verification';
import RetrievePassword from './ui-components/Login/RetrievePassword';
import RetrievePasswordConfirmation from './ui-components/Login/RetrievePasswordConfirmation';

import Dashboard from './ui-components/Dashboard';
import Profile from './ui-components/Settings/Profile';
import Account from './ui-components/Settings/Account';
import MultiFactor from './ui-components/Settings/MultiFactor';
import MultiFactorEmail from './ui-components/Settings/MultiFactorEmail';
import SecondFactorChallenge from './ui-components/Login/SecondFactorChallenge';
import MultiFactorApp from './ui-components/Settings/MultiFactorApp';
import MultiFactorDisable from './ui-components/Settings/MultiFactorDisable';
import i18n from './i18n';
import OrganisationSettings from './ui-components/Organisation/OrganisationSettings';
import UserManagement from './ui-components/Organisation/UserManagement';
import Subscriptions from './ui-components/Organisation/Subscriptions';
import OrganisationInvite from './ui-components/Organisation/OrganisationInvite';
import OrganizationList from './ui-components/Organisation/OrganizationList';
import DeleteOrganization from './ui-components/Organisation/DeleteOrganization';
import DeleteAccount from './ui-components/Settings/DeleteAccount';
import ActivateAccount from './ui-components/Settings/ActivateAccount';
import VerificationWait from './ui-components/Login/VerificationWait';
import LandingPage from './ui-components/LandingPage';
import ThemeContextProvider, { ThemeContext } from './ui-components/Images/ThemeContext';
import UserContextProvider from './ui-components/Model/UserContext';

function App() {
  const [cookie] = useCookies(['language']);
  const languages = ['en', 'de'];
  const { themeColor } = useContext(ThemeContext);

  useEffect(() => {
    if (!languages.includes(navigator.language.trim().slice(0, 2)))
      i18n.changeLanguage('en');

    if (cookie.language !== navigator.language.trim().slice(0, 2))
      i18n.changeLanguage(cookie.language);
  }, []);

  return (
    <div className="App" data-theme={themeColor}>
      <ThemeContextProvider>
        <UserContextProvider>
          <Routes>
            <Route element={<Dashboard />}>
              <Route path="/" element={<LandingPage />} />
              <Route path="Dashboard" element={<LandingPage />} />
              <Route path="Profile" element={<Profile />} />
              <Route path="Account" element={<Account />} />
              <Route path="DeleteAccount" element={<DeleteAccount />} />
              <Route path="ActivateAccount" element={<ActivateAccount />} />
              <Route path="MultiFactor" element={<MultiFactor />} />
              <Route path="MultiFactorEmail" element={<MultiFactorEmail />} />
              <Route path="MultiFactorApp" element={<MultiFactorApp />} />
              <Route path="MultiFactorDisable" element={<MultiFactorDisable />} />
              <Route path="Organization" element={<OrganisationSettings CreateNewToggle={false} />} />
              <Route path="OrganizationList" element={<OrganizationList />} />
              <Route path="CreateOrganization" element={<OrganisationSettings CreateNewToggle />} />
              <Route path="DeleteOrganization" element={<DeleteOrganization />} />
              <Route path="Subscriptions" element={<Subscriptions />} />
              <Route path="Users" element={<UserManagement />} />
            </Route>
            <Route element={<Loginpage />}>
              <Route path="Login" element={<Login />} />
              <Route path="Login/:Service" element={<Login />} />
              <Route path="SecondFactorChallenge" element={<SecondFactorChallenge />} />
              <Route path="Registration" element={<Registration />} />
              <Route path="Verification" element={<Verification />} />
              <Route path="VerificationWait" element={<VerificationWait />} />
              <Route path="RetrievePassword" element={<RetrievePassword />} />
              <Route path="RetrievePasswordConfirmation" element={<RetrievePasswordConfirmation />} />
            </Route>
            <Route element={<OrganisationInvite />}>
              <Route path="OrganizationInvite/" element={<OrganisationInvite />} />
              <Route path="OrganizationInvite/:inviteID" element={<OrganisationInvite />} />
            </Route>
          </Routes>
        </UserContextProvider>
      </ThemeContextProvider>
    </div>
  );
}

export default App;

/* eslint-disable max-len */
import './StyleSheets/main.css';
import Ger from './Images/flags/GER.png';
import Nl from './Images/flags/NL.png';
import AT from './Images/flags/AT.svg';
import UK from './Images/flags/UK.png';
import BG from './Images/flags/BG.svg';
import CZ from './Images/flags/CZ.svg';
import DK from './Images/flags/DK.svg';
import FR from './Images/flags/FR.svg';
import IT from './Images/flags/IT.svg';
import LU from './Images/flags/LU.svg';
import PL from './Images/flags/PL.svg';
import SP from './Images/flags/SP.svg';
import SW from './Images/flags/SW.svg';

export function CountryCodes() {
  return [
    {
      value: '+49',
      label: CountryCodeLabel(Ger, '+49'),
    },
    {
      value: '+43',
      label: CountryCodeLabel(AT, '+43'),
    },
    {
      value: '+31',
      label: CountryCodeLabel(Nl, '+31'),
    },
    {
      value: '+33',
      label: CountryCodeLabel(FR, '+33'),
    },
    {
      value: '+34',
      label: CountryCodeLabel(SP, '+34'),
    },
    {
      value: '+44',
      label: CountryCodeLabel(UK, '+44'),
    },
    {
      value: '+45',
      label: CountryCodeLabel(DK, '+45'),
    },
    {
      value: '+39',
      label: CountryCodeLabel(IT, '+39'),
    },
    {
      value: '+32',
      label: CountryCodeLabel(BG, '+32'),
    },
    {
      value: '+48',
      label: CountryCodeLabel(PL, '+48'),
    },
    {
      value: '+41',
      label: CountryCodeLabel(SW, '+41'),
    },
    {
      value: '+420',
      label: CountryCodeLabel(CZ, '+420'),
    },
    {
      value: '+352',
      label: CountryCodeLabel(LU, '+352'),
    },
  ];
}

function CountryCodeLabel(flag: any, CountryCode: string) {
  return (
    <div className="CountryCodeLabel">
      <img
        id="CountryCodeFlag"
        src={flag}
        alt=""
      />
      <span>{CountryCode}</span>
    </div>
  );
}
export default CountryCodes;

/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { Session, useSession } from './Loginpage';
import '../StyleSheets/main.css';
import '../StyleSheets/login.css';
import Urls from '../Urls';

export default function Verification() {
  const navigate = useNavigate();
  const [session, setSession] = useSession();

  if (session.registeredMail === undefined || session.registeredPasswort === undefined)
    navigate('/Registration');

  const [verificationCode, setverificationCode] = useState('');
  const onVerify = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const newSession = new Session(session);
      newSession.mail = session.registeredMail;
      newSession.password = session.registeredPasswort;
      newSession.signupConfirmationCode = verificationCode;
      setSession(newSession);
      navigate(Urls.VerificationWait);
    } catch (error: any) {
      toast.error(error, {
        position: 'bottom-right',
        autoClose: 3000,
      });
    }
  };

  return (
    <div id="loginbox">
      <div id="login-body">
        <h3 id="single-text">{t('Please verify your E-Mail-address')}</h3>
        <a id="single-text">{t('We have send you an E-Mail to the e-mail-address {{email, string}}.', { email: session.registeredMail })}</a>
        <a id="single-text">{t('To confirm your E-Mail-address please type in the send verification code.')}</a>
        <input id="loginbox-input" autoFocus type="text" onChange={(x) => setverificationCode(x.target.value)} placeholder={t('Verification Code')} />
        <button id="loginbox-button" type="submit" className="basicButton" onClick={onVerify}>
          {t('Verify')}
        </button>
        <button id="loginbox-button" type="submit" className="basicButton" onClick={() => navigate('/Registration')}>
          {t('back')}
        </button>
      </div>
    </div>
  );
}

/* eslint-disable max-len */
import { Auth } from 'aws-amplify';
import { t } from 'i18next';
import '../StyleSheets/main.css';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useContext, useEffect, useState } from 'react';
import { useOrganizationHeaders } from '../Dashboard';
import { LoadOrganizations, ReactivateOrganization } from './OrganisationLoader';
import { OrganizationMetaData } from './OrganisationSettings';
import { DefaultToastConfig, FixedToast } from '../ToastConfig';
import { ServiceRole } from '../Model/Enums';
import { GetOrganizationLogo } from '../Settings/AvatarRequests';
import { ThemeContext } from '../Images/ThemeContext';

export default function OrganizationList() {
  const [data, setData, setcurrentOrganizationImage] = useOrganizationHeaders();
  const [organizationImages, setOrganizationImages] = useState<Map<string | number, any>>(new Map<string | number, any>());
  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);
  const list = data.organisationMetaData?.map((org : OrganizationMetaData) => (
    <div id="OrganizationListTile">
      <form>
        <img
          src={organizationImages.has(org.ID) ? organizationImages.get(org.ID) : theme.Building}
          alt=""
          id="OrganizationMenuImg"
        />
        <div id="BlockHeadLine">
          <h3>{org.Name}</h3>
        </div>
        <button disabled={!org.IsActive && !org.MemberRoles?.some((x) => x === ServiceRole.Admin)} onClick={(e: any) => { e.preventDefault(); switchOrganization(org); }}>
          {org.IsActive ? t('Switch') : t('Reactivate')}
        </button>

      </form>
    </div>
  ));

  async function Load() {
    if (!data.loadingFinished)
      return;
    for (const organization of data.organisationMetaData!) {
      setOrganizationImages(new Map<string | number, any>(organizationImages.set(organization.ID, await GetOrganizationLogo(organization.ID.toString(), theme, organization.Name!))));
    }
  }
  useEffect(() => { Load(); }, [data]);

  async function switchOrganization(org: OrganizationMetaData) {
    if (!org.IsActive) {
      await ReactivateOrganization(org.ID.toString());
      toast.info(t('Organization has been reactivated'), FixedToast);
    }
    const user = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(user, {
      'custom:CurrentOrganization': org.ID.toString(),
    });
    await LoadOrganizations(setData);
    toast.info(t('Changed to Organization {{name}}', { name: org.Name }), DefaultToastConfig);
    const logo = await GetOrganizationLogo(org.ID.toString(), theme, org.Name);
    setcurrentOrganizationImage(logo);
    navigate('/');
  }

  return (
    <div id="PageBody">
      <div id="HeadLine">
        <h2>{t('Organizationlist')}</h2>
      </div>
      <div id="OrganizationListGrid">
        {!data.loadingFinished && <i className="Verificationloader" />}
        {list}
      </div>
    </div>
  );
}

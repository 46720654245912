import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import PasswordChecklist from '../PasswordChecklist';
import { useSession } from './Loginpage';
import i18n from '../../i18n';
import google from '../Images/google.png';
import '../StyleSheets/login.css';
import '../StyleSheets/main.css';
import validateEmail from '../validateEmail';
import { federatedSignIn } from './Login';

export default function Registration() {
  const navigate = useNavigate();
  const [mail, setMail] = useState('');
  const [open, setOpen] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [session, setSession] = useSession();

  const [password, setpassword] = useState<string>('');
  const [reEnteredPasswort, setreEnteredPasswort] = useState('');

  const onRegister = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsRegistering(true);
    try {
      if (!validateInput()) {
        setIsRegistering(false);
        return;
      }
      await Auth.signUp({
        username: mail,
        password,
        attributes: {
          locale: i18n.language,
        },
      });
    } catch (error) {
      console.log('error signing up:', error);
      setIsRegistering(false);
      toast.error(t('Error during sign up.'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      return;
    }
    session.registeredMail = mail;
    session.registeredPasswort = password;
    setSession(session);
    setIsRegistering(false);
    navigate('/Verification');
  };

  function validateInput() {
    if (mail === '') {
      toast.error(t('Missing E-Mail'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      return false;
    }
    if (password === '') {
      toast.error(t('Missing Password'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      return false;
    }
    if (!validateEmail(mail)) {
      toast.error(t('Please enter a valid email address.'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      return;
    }
    if (password !== reEnteredPasswort) {
      toast.error(t('Passwords do not match.'), {
        position: 'bottom-right',
        autoClose: 3000,
      });
      return false;
    }
    return true;
  }

  return (
    <div id="loginbox">
      <div id="login-body">
        <form>
          <input id="loginbox-input" type="text" autoFocus onChange={(x) => setMail(x.target.value)} placeholder={t('E-Mail')} />
          <div onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            <input id="loginbox-input" type="password" onChange={(x) => setpassword(x.target.value)} placeholder={t('Password')} />
            <input id="loginbox-input" type="password" onChange={(x) => setreEnteredPasswort(x.target.value)} placeholder={t('Repeat password')} />
          </div>
          {open && <PasswordChecklist password={password} onIsValidChanged={setIsValid} passwordAgain={reEnteredPasswort} />}
          <button id="loginbox-button" type="submit" className="basicButton" onClick={onRegister} disabled={!isValid}>
            {isRegistering && <i className="loader" />}
            {t('Weiter')}
          </button>
          <button id="loginbox-button" type="submit" className="basicButton" onClick={() => navigate('/Login')}>
            {t('back')}
          </button>
        </form>
        <div hidden>
          <div id="line" />
          <h2>{t('or sign up with')}</h2>
          <div id="line" />
        </div>
        <div id="social-icons" hidden>
          <button id="social-button" onClick={() => federatedSignIn(session)}>
            <img
              src={google}
              alt=""
            />
            {t('Google')}
          </button>
        </div>
      </div>
    </div>
  );
}
